import React from "react";
import ChatWidget from "../components/ChatWidget";
import Footer from "../components/Footer";
import HorizontalNavbar from "../components/HorizontalNavbar";
import { motion } from "framer-motion";
import UserProvider from "../context/UserContext";
const Layout = ({ children }) => {
  return (
    <>
      <UserProvider>
        <HorizontalNavbar />
        <motion.div exit={{ opacity: 0 }}>
          <main>{children}</main>
          <ChatWidget />
        </motion.div>
        <Footer />
      </UserProvider>
    </>
  );
};

export default Layout;
