import React, { useEffect, useContext } from "react";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, UserCircleIcon, XIcon } from "@heroicons/react/outline";
import { Outlet, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import useToken from "../components/useToken";
import { UserContext } from "../context/UserContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function HorizontalNavbar({ children }) {
  const location = useLocation();
  const { token } = useToken();
  const { userRole } = useContext(UserContext);
  useEffect(() => {}, [token]);
  var navigation = [
    { name: "Home", href: "/" },
    { name: "Booking", href: "/booking" },
    { name: "Packages/Discount", href: "/package" },
    { name: "Contact Us", href: "/contact" },
    { name: "Gallery", href: "/gallery" },
  ];

  var adminNavigation = [
    { name: "Home", href: "/" },
    { name: "Booking", href: "/booking" },
    { name: "Packages/Discount", href: "/package" },
    { name: "Contact Us", href: "/contact" },
    { name: "Gallery", href: "/gallery" },
    { name: "Admin", href: "/admin/1" },
  ];

  var anonymousNavigation = [{ name: "Login", href: "/signin" }];
  var userNavigation = [{ name: "Account", href: "/account" }];

  return (
    <>
      <header className="object-scale-down">
        <nav className="mx-0 md:mx-auto px-4 px-0 md:px-6 " aria-label="Top">
          {/** Website **/}
          <div className="items-end flex justify-between py-6 hidden md:flex">
            <div className="block">
              <a href="/">
                <center>
                  <img
                    className="h-[120px] w-auto object-scale-down"
                    src="https://studiotableau.hk/images/Logo.webp"
                    alt=""
                  />
                </center>
              </a>
            </div>
            <div className="flex flex-wrap justify-center md:space-x-16 h-0 md:h-auto md:p-4 block md:inline-flex">
              {userRole === "user" || userRole === "guest"
                ? navigation.map((link) => (
                    <Link
                      key={link.name}
                      to={link.href}
                      className="text-base font-medium py-0 md:py-2 text-gray-600 hover:text-black whitespace-nowrap"
                    >
                      {link.name}
                    </Link>
                  ))
                : adminNavigation.map((link) => (
                    <Link
                      key={link.name}
                      to={link.href}
                      className="text-base font-medium py-0 md:py-2 text-gray-600 hover:text-black whitespace-nowrap"
                    >
                      {link.name}
                    </Link>
                  ))}
            </div>

            <div className="flex flex-wrap justify-center md:space-x-16 h-0 md:h-auto md:p-4 block md:inline-flex">
              {!token
                ? anonymousNavigation.map((link) => (
                    <Link
                      key={link.name}
                      to={link.href}
                      className="flex text-base font-medium py-0 text-gray-600 hover:text-black whitespace-nowrap"
                    >
                      <UserCircleIcon
                        className="h-6 w-6  mr-2 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />

                      {link.name}
                    </Link>
                  ))
                : userNavigation.map((link) => (
                    <Link
                      key={link.name}
                      to={link.href}
                      className="flex text-base font-medium py-0 text-gray-600 hover:text-black whitespace-nowrap"
                    >
                      <UserCircleIcon
                        className="h-6 w-6  mr-2 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />

                      {link.name}
                    </Link>
                  ))}
            </div>
          </div>

          {/** Mobile **/}
          <div className="min-h-full sticky top-0 z-50 bg-white mt-8 md:mt-0 md:shadow-lg">
            <Disclosure
              as="nav"
              className="drop-shadow-sm visbile md:invisible"
            >
              {({ open }) => (
                <>
                  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-3  md:pb-0">
                    <div className="flex justify-between h-20 md:h-0 mb-12 md:mb-0">
                      <a href="/">
                        <img
                          className="md:h-[120px] h-[80px] w-auto object-scale-down"
                          src="https://studiotableau.hk/images/Logo.webp"
                          alt=""
                        />
                      </a>
                      <div className="-mr-2 flex md:hidden">
                        {/* Mobile menu button */}
                        <Disclosure.Button className="bg-gray-50 h-10 w-10 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FCF1ED]">
                          <span className="sr-only">Open main menu</span>
                          {open ? (
                            <XIcon
                              className="block h-6 w-6 md:h-0"
                              aria-hidden="true"
                            />
                          ) : (
                            <MenuIcon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </Disclosure.Button>
                      </div>
                    </div>
                  </div>

                  <Disclosure.Panel className="md:hidden">
                    <div className="pt-2 pb-3 space-y-1">
                      {userRole === "user" || userRole === "guest"
                        ? navigation.map((item) => (
                            <Disclosure.Button
                              key={item.name}
                              as="a"
                              href={item.href}
                              target={
                                item.href.startsWith("http")
                                  ? "_blank"
                                  : "_self"
                              }
                              className={classNames(
                                location.pathname === item.href
                                  ? "bg-[#FCF1ED] border-[#FCF1ED] text-[#796B5F]"
                                  : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800",
                                "block pl-3 pr-4 py-2 border-l-4 text-base font-medium whitespace-nowrap"
                              )}
                              aria-current={
                                location.pathname === item.href
                                  ? "page"
                                  : undefined
                              }
                            >
                              {item.name}
                            </Disclosure.Button>
                          ))
                        : adminNavigation.map((item) => (
                            <Disclosure.Button
                              key={item.name}
                              as="a"
                              href={item.href}
                              target={
                                item.href.startsWith("http")
                                  ? "_blank"
                                  : "_self"
                              }
                              className={classNames(
                                location.pathname === item.href
                                  ? "bg-[#FCF1ED] border-[#FCF1ED] text-[#796B5F]"
                                  : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800",
                                "block pl-3 pr-4 py-2 border-l-4 text-base font-medium whitespace-nowrap"
                              )}
                              aria-current={
                                location.pathname === item.href
                                  ? "page"
                                  : undefined
                              }
                            >
                              {item.name}
                            </Disclosure.Button>
                          ))}
                      {!token
                        ? anonymousNavigation.map((item) => (
                            <Disclosure.Button
                              key={item.name}
                              as="a"
                              href={item.href}
                              target={
                                item.href.startsWith("http")
                                  ? "_blank"
                                  : "_self"
                              }
                              className={classNames(
                                location.pathname === item.href
                                  ? "bg-[#FCF1ED] border-[#FCF1ED] text-[#796B5F]"
                                  : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800",
                                "block pl-3 pr-4 py-2 border-l-4 text-base font-medium whitespace-nowrap"
                              )}
                              aria-current={
                                location.pathname === item.href
                                  ? "page"
                                  : undefined
                              }
                            >
                              {item.name}
                            </Disclosure.Button>
                          ))
                        : userNavigation.map((item) => (
                            <Disclosure.Button
                              key={item.name}
                              as="a"
                              href={item.href}
                              target={
                                item.href.startsWith("http")
                                  ? "_blank"
                                  : "_self"
                              }
                              className={classNames(
                                location.pathname === item.href
                                  ? "bg-[#FCF1ED] border-[#FCF1ED] text-[#796B5F]"
                                  : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800",
                                "block pl-3 pr-4 py-2 border-l-4 text-base font-medium whitespace-nowrap"
                              )}
                              aria-current={
                                location.pathname === item.href
                                  ? "page"
                                  : undefined
                              }
                            >
                              {item.name}
                            </Disclosure.Button>
                          ))}
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        </nav>
      </header>
      <Outlet />
      {children}
    </>
  );

  return (
    <>
      <div>
        <div className="min-h-full sticky top-0 z-50">
          <Disclosure as="nav" className="bg-white drop-shadow-sm">
            {({ open }) => (
              <>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-3">
                  <div className="flex justify-between h-16">
                    <div className="flex">
                      <div className="flex-shrink-0 flex items-center">
                        <Link to="/">
                          <img
                            className="block h-10 w-auto"
                            src="./images/Tableau.webp"
                            alt="studio tableau"
                          />
                        </Link>
                      </div>
                      <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                        {navigation.map((item) =>
                          item.href.startsWith("http") ? (
                            <a
                              href={item.href}
                              key={item.name}
                              target="_blank"
                              className={classNames(
                                location.pathname === item.href
                                  ? "border-[#FCF1ED] text-gray-900 font-bold"
                                  : "border-transparent text-gray-400 hover:text-gray-700 hover:border-gray-300",
                                "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                              )}
                              aria-current={
                                location.pathname === item.href
                                  ? "page"
                                  : undefined
                              }
                            >
                              {item.name}
                            </a>
                          ) : (
                            <Link
                              key={item.name}
                              to={item.href}
                              className={classNames(
                                location.pathname === item.href
                                  ? "border-[#FCF1ED] text-gray-900 font-bold"
                                  : "border-transparent text-gray-400 hover:text-gray-700 hover:border-gray-300",
                                "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                              )}
                              aria-current={
                                location.pathname === item.href
                                  ? "page"
                                  : undefined
                              }
                            >
                              {item.name}
                            </Link>
                          )
                        )}
                      </div>
                    </div>

                    <div className="-mr-2 flex items-center sm:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                          <MenuIcon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="sm:hidden">
                  <div className="pt-2 pb-3 space-y-1">
                    {navigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={
                          item.href.startsWith("http")
                            ? item.href
                            : "#" + item.href
                        }
                        target={
                          item.href.startsWith("http") ? "_blank" : "_self"
                        }
                        className={classNames(
                          location.pathname === item.href
                            ? "bg-yellow-50 border-[#FCF1ED] text-[#796B5F]"
                            : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800",
                          "block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                        )}
                        aria-current={
                          location.pathname === item.href ? "page" : undefined
                        }
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
        <Outlet />
      </div>
      {children}
    </>
  );
}
export default HorizontalNavbar;
