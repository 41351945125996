import React from "react";
import Layout from "./Layout";
import { motion } from "framer-motion";
import AdminCreateBooking from "../components/AdminCreateBooking";

export const CreateBooking = () => {
  return (
    <Layout>
      <motion.div exit={{ opacity: 0 }}>
        <AdminCreateBooking />
      </motion.div>
    </Layout>
  );
};
