import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useToken from "../components/useToken";
export const Logout = () => {
  const { token, setToken } = useToken();
  const navigate = useNavigate();
  useEffect(() => {
    clearToken();
  });

  function clearToken() {
    console.log(token);
    setToken(null);
    localStorage.clear("token");
    navigate("/");
  }

  return <div></div>;
};
