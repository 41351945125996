import React from "react";

export const VerifyEmail = (email) => {
  return (
    <div className="bg-gray-50 p-8">
      <div className="flex items-center p-8 justify-center h-96 min-w-screen">
        <div className="max-w-xl p-8 text-center text-gray-800 bg-white shadow-sm lg:max-w-3xl rounded-2xl lg:p-12">
          <h3 className="text-2xl">
            Thanks you for signing up with <br />
            Studio Tableau
          </h3>
          <div className="flex justify-center p-8">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-24 h-24 text-green-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1"
                d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76"
              />
            </svg>
          </div>

          <p>
            We have sent you an activation link to{" "}
            <a
              href="#"
              className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
            >
              {email.email}
            </a>
            .
            <br />
            Please check your email and verify your account.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};
