import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { LoadingWidget } from "./LoadingWidget";
export default function InfoPackage() {
  let { id } = useParams();
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setInfo({});
        axios
          .get("https://studio-tableau-prd.onrender.com/api/package/" + id, {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
          .then((res) => {
            console.log(res.data);
            setInfo(res.data);
            setLoading(false);
          });

        // console.log(response.json());
        // setInfo(response.json());
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  if (loading) return <LoadingWidget />;
  else
    return (
      <div className="relative bg-white">
        <div className="relative mx-auto max-w-md px-6 sm:max-w-3xl lg:px-0">
          {/* Content area */}
          <div className="pt-12 sm:pt-16 lg:pt-20">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {info.name}
            </h2>{" "}
            <br />
            <Link
              to="/cart"
              state={{
                product: info,
              }}
            >
              <button className="bg-[#FCF1ED] text-lg hover:[#C6816A] text-[#9B9188] py-1 px-12 rounded-full">
                Book Now
              </button>
            </Link>
            <div className="mt-6 space-y-6 text-gray-500">
              <p className="text-base leading-7">{info.eng_name}</p>
              <p className="text-lg">
                ${info.price}/ {info.durations} hours
              </p>
              <p className="text-lg whitespace-pre-line">
                <font className="font-semibold">Description:</font>
                <br />
                {info.description}
              </p>
              <p className="text-lg whitespace-pre-line">
                <font className="font-semibold">Terms and conditions:</font>
                <br />
                {info.tnc}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
}
