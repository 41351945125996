import React from "react";
import Layout from "./Layout";
import { motion } from "framer-motion";
import { AdminWidget } from "../components/AdminWidget";

export const Admin = () => {
  return (
    <Layout>
      <motion.div exit={{ opacity: 0 }}>
        <AdminWidget />
      </motion.div>
    </Layout>
  );
};
