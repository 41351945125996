import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function BookingWidget() {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setProducts([]);
        const response = await fetch(
          "https://studio-tableau-prd.onrender.com/api/product",
          {
            method: "get",
            headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
            },
          }
        );
        const string = await response.text();

        const json = string === "" ? {} : JSON.parse(string);

        setProducts(json);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="bg-white ">
      <div className=" mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8 ">
        <div className="space-y-12">
          <center>
            <h3 className="text-3xl tracking-tight sm:text-4xl">Booking</h3>
          </center>
          <ul
            duration="list"
            className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8"
          >
            {products.map((product) => (
              <li key={product.id}>
                <div className="space-y-4 border border-amber-800  text-center">
                  <div className="aspect-w-3 aspect-h-2">
                    <LazyLoadImage
                      // height={image.height}
                      src={product.img}
                      // width={image.width}
                    />
                    {/* <img
                      className="object-cover"
                      src={product.img}
                      alt=""
                      loading="lazy"
                    /> */}
                  </div>

                  <div className="space-y-2 px-6 pb-5 ">
                    <div className="space-y-1 text-lg font-medium leading-6">
                      <span>{product.name}</span>
                      <h3 className="pb-2">{product.eng_name}</h3>
                      <Link
                        to={"/bookingInfo/" + product.id}
                        // state={{ product: product }}
                      >
                        <i className="underline text-[#c5c5c5]">More</i>
                      </Link>
                      <div className="relative p-2">
                        <div
                          className="absolute inset-0 flex items-center"
                          aria-hidden="true"
                        >
                          <div className="w-full border-t border-gray-300" />
                        </div>
                      </div>
                      {product.durations > 1
                        ? product.durations + " Hours"
                        : product.durations + " Hour"}
                      <br />$
                      {(product.price * product.durations).toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits: 2,
                        }
                      )}
                      <br /> <br />
                      <Link to="/start_booking" state={{ product: product }}>
                        <button
                          // onClick={() => {
                          //   getUserRemainingHours(product);
                          // }}
                          className="bg-[#FCF1ED] text-lg hover:[#C6816A] text-[#9B9188] py-1 px-12 rounded-full"
                        >
                          Book Now
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
