import React, { useEffect, useState } from "react";
import { LoadingWidget } from "./LoadingWidget";
import useToken from "../components/useToken";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import moment from "moment";
export const AdminBooking = () => {
  const navigate = useNavigate();
  var today = new Date();
  var toDate = new Date(new Date().setMonth(new Date().getMonth() + 3));

  const [loading, setLoading] = useState(true);
  const [totalItems, setTotalItems] = useState();
  const [bookingList, setBookingList] = useState([]);
  const [selected, setSelected] = useState();
  const { token } = useToken();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://studio-tableau-prd.onrender.com/api/admin/getAllBookings",
        {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const string = await response.text();

      const json = string === "" ? {} : JSON.parse(string);
      console.log(json);
      // setPendingNumber(json.pendingTransactions);
      setTotalItems(json.totalItems);
      setBookingList(json.rows);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const voidBooking = async (id) => {
    try {
      // let body = JSON.stringify({ orderNumber: orderNumber });
      await fetch(
        `https://studio-tableau-prd.onrender.com/api/admin/booking/` + id,
        {
          method: "delete",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTotalItems(totalItems - 1);
      // setBookingList(json.rows);
      setBookingList(bookingList.filter((item) => item.booking_sn !== id));
    } catch (error) {
      console.log(error);
    }
  };

  const refetchByDate = async (date) => {
    try {
      // setLoading(true);
      setSelected(date);
      let body = selected
        ? JSON.stringify({ date: moment(date).format("YYYY-MM-DD") })
        : JSON.stringify({});
      const response = await fetch(
        "https://studio-tableau-prd.onrender.com/api/admin/getAllBookings",
        {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: body,
        }
      );
      const string = await response.text();
      const json = string === "" ? {} : JSON.parse(string);
      // setPendingNumber(json.pendingTransactions);
      setTotalItems(json.totalItems);
      setBookingList(json.rows);
      // setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const mapName = (name) => {
    switch (name) {
      case "self_recording":
        return "自助錄音";
      case "practice":
        return "練習";
      case "professional_recording":
        return "專業錄音";
      case "practice,self_recording":
        return "練習+自助錄音";
      case "practice,professional_recording":
        return "練習+專業錄音";
      case "recital":
        return "演奏廳租用";
      case "admin":
        return "Admin reserve";
      default:
        return "";
    }
  };

  if (loading) return <LoadingWidget />;
  else
    return (
      <div>
        <br />
        <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
          <div className="mb-4 flex items-center justify-between">
            <div>
              <h3 className="text-xl font-bold text-gray-900 mb-2">
                All Bookings ({totalItems})
              </h3>
              <span className="text-base font-normal text-gray-500">
                This is a list of all bookings
              </span>
            </div>
            <div className="flex-shrink-0">
              <div className="pt-2 relative mx-auto text-gray-600">
                <a
                  href="https://studio-tableau-prd.onrender.com/api/admin/exportBookingList"
                  class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
                >
                  Export
                </a>
                <button
                  onClick={() => navigate("/createBooking")}
                  class="ml-2 bg-green-400 hover:bg-green-600 text-green-800 font-bold py-2 px-4 rounded inline-flex items-center"
                >
                  Create
                </button>
              </div>
            </div>
          </div>
          <div className="hidden container max-w-screen-lg mx-auto pt-4 lg:block">
            {/* <style>{css}</style> */}
            <DayPicker
              mode="single"
              numberOfMonths={3}
              toDate={toDate}
              selected={selected}
              onSelect={refetchByDate}
              // locale={zh}
            />
          </div>
          <div className="hidden container max-w-screen-lg mx-auto pt-4 sm:block lg:hidden">
            {/* <style>{css}</style> */}
            <DayPicker
              mode="single"
              numberOfMonths={2}
              toDate={toDate}
              selected={selected}
              onSelect={refetchByDate}
              // locale={zh}
            />
          </div>
          <div className="container max-w-screen-lg mx-auto pt-4 sm:hidden">
            <DayPicker
              mode="single"
              numberOfMonths={1}
              toDate={toDate}
              selected={selected}
              onSelect={refetchByDate}
              // locale={zh}
            />
          </div>
          {!selected && (
            <center>
              <b>All Bookings</b>
            </center>
          )}
          {selected && (
            <center>
              <b>Bookings on {format(selected, "PP")}</b>
            </center>
          )}
          <div className="flex flex-col mt-8">
            <div className="overflow-x-auto rounded-lg">
              <div className="align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          User ID
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Order number
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Type
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Time
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Created At
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {bookingList.length == 0 && (
                        <tr>
                          <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                            No booking
                          </td>
                        </tr>
                      )}
                      {bookingList.map((data) => (
                        <tr
                          // onClick={() =>
                          //   navigate(`/user_details/${data.user_id}`)
                          // }
                          key={data.booking_sn}
                          className="transition duration-300 ease-in-out hover:bg-gray-100"
                        >
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-500">
                            {data.user_id ?? "NULL"}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            {data.booking_sn}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            {mapName(data.type)}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            {data.date}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            {data.start_time.split(":")[0] +
                              ":" +
                              data.start_time.split(":")[1]}
                            -
                            {parseInt(data.start_time.split(":")[0]) +
                              data.duration +
                              ":00"}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            {moment(data.created_at).format("YYYY-MM-DD HH:mm")}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            <button
                              onClick={() =>
                                navigate(`/user_details/${data.user_id}`)
                              }
                              className="bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded"
                            >
                              VIEW
                            </button>
                            <button
                              className="ml-2 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded"
                              onClick={() => voidBooking(data.booking_sn)}
                            >
                              VOID
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    );
};
