import React from "react";
import { ForgetPasswordWidget } from "../components/ForgetPasswordWidget";

import Layout from "./Layout";
import { motion } from "framer-motion";
export const ForgetPassword = () => {
  return (
    <Layout>
      <motion.div exit={{ opacity: 0 }}>
        <ForgetPasswordWidget />
      </motion.div>
    </Layout>
  );
};
