import React from "react";
import Layout from "./Layout";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { FpsWidget } from "../components/FpsWidget";
export const Fps = () => {
  const location = useLocation();
  return (
    <Layout>
      <motion.div exit={{ opacity: 0 }}>
        <FpsWidget
          order_number={location.state.order_number}
          price={location.state.price}
        />
      </motion.div>
    </Layout>
  );
};
