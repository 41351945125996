import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useToken from "../components/useToken";
import { LoadingWidget } from "./LoadingWidget";
import { useNavigate } from "react-router-dom";
export default function AdminUserDetails() {
  const [info, setInfo] = useState({});
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  let { id } = useParams();
  const { token } = useToken();
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://studio-tableau-prd.onrender.com/api/users/${id}`,
        {
          method: "get",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const string = await response.text();
      const json = string === "" ? {} : JSON.parse(string);
      setInfo(json.userData);
      setBookings(json.allBooking);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const mapName = (name) => {
    switch (name) {
      case "self_recording":
        return "自助錄音";
      case "practice":
        return "練習";
      case "professional_recording":
        return "專業錄音";
      case "practice,self_recording":
        return "練習+自助錄音";
      case "practice,professional_recording":
        return "練習+專業錄音";
      case "recital":
        return "演奏廳租用";
      default:
        return "";
    }
  };

  const activateAccount = async () => {
    try {
      const response = await fetch(
        `https://studio-tableau-prd.onrender.com/api/users/${id}`,
        {
          method: "put",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const string = await response.text();
      const json = string === "" ? {} : JSON.parse(string);
      console.log(json);
      window.location.reload(false);
    } catch (error) {
      console.log(error);
    }
  };

  const voidBooking = async (id) => {
    try {
      // let body = JSON.stringify({ orderNumber: orderNumber });
      await fetch(
        `https://studio-tableau-prd.onrender.com/api/admin/booking/` + id,
        {
          method: "delete",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAccount = async () => {
    try {
      let body = JSON.stringify({ id: id });
      await fetch(`https://studio-tableau-prd.onrender.com/api/users/${id}`, {
        method: "delete",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: body,
      });
      navigate("/admin/4");
    } catch (error) {
      console.log(error);
    }
  };

  const confirmPayment = async (orderNumber) => {
    try {
      console.log(orderNumber);
      let body = JSON.stringify({ orderNumber: orderNumber });
      console.log(body);
      await fetch(
        `https://studio-tableau-prd.onrender.com/api/payment/confirmPayment`,
        {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: body,
        }
      );
      setLoading(true);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const rejectPayment = async (orderNumber) => {
    try {
      console.log(orderNumber);
      let body = JSON.stringify({ orderNumber: orderNumber });
      console.log(body);
      await fetch(
        `https://studio-tableau-prd.onrender.com/api/payment/rejectPayment`,
        {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: body,
        }
      );
      setLoading(true);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) return <LoadingWidget />;
  else
    return (
      <>
        <div className="min-h-full">
          <main className="py-10 bg-gray-50">
            {/* Page header */}
            <div className="mx-auto max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
              <div className="flex items-center space-x-5">
                <div>
                  <h1 className="text-2xl font-bold text-gray-900">
                    {info.last_name} {info.first_name}
                  </h1>
                  <p className=" font-medium text-gray-500">
                    Registered at
                    <a href="#" className="text-gray-900">
                      {" " + moment(info.createdAt).format("YYYY-MM-DD HH:mm")}
                    </a>
                  </p>
                </div>
              </div>
              <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                <button
                  type="button"
                  onClick={deleteAccount}
                  className="inline-flex items-center justify-center rounded-md bg-white px-3 py-2  font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  Delete
                </button>
                {info.status == 0 && (
                  <button
                    type="button"
                    onClick={activateAccount}
                    className="inline-flex items-center justify-center rounded-md bg-blue-600 px-3 py-2  font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    Activate
                  </button>
                )}
              </div>
            </div>

            <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2">
              <div className="space-y-6 lg:col-span-2 lg:col-start-1">
                {/* Description list*/}
                <section aria-labelledby="applicant-information-title">
                  <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                      <h2
                        id="applicant-information-title"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        User Information
                      </h2>
                      <p className="mt-1 max-w-2xl  text-gray-500">
                        Personal details.
                      </p>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-1">
                          <dt className=" font-medium text-gray-500">Phone</dt>
                          <dd className="mt-1  text-gray-900">
                            {info.phone_number}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className=" font-medium text-gray-500">
                            Email address
                          </dt>
                          <dd className="mt-1  text-gray-900">{info.email}</dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className=" font-medium text-gray-500">Role</dt>
                          <dd className="mt-1  text-gray-900">{info.role}</dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className=" font-medium text-gray-500">Status</dt>
                          <dd className="mt-1  text-gray-900">
                            {info.status == 1
                              ? "Activated"
                              : "Not yet verified"}
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                </section>

                {/* Booking lists */}
                <section aria-labelledby="applicant-information-title">
                  <div className="bg-white shadow sm:rounded-lg">
                    <div className="flex flex-col mt-8">
                      <div className="overflow-x-auto rounded-lg">
                        <div className="align-middle inline-block min-w-full">
                          <div className="shadow overflow-hidden sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Order Number
                                  </th>
                                  <th
                                    scope="col"
                                    className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Type
                                  </th>
                                  <th
                                    scope="col"
                                    className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Date
                                  </th>
                                  <th
                                    scope="col"
                                    className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Booked Time
                                  </th>
                                  <th
                                    scope="col"
                                    className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {bookings.length > 0 &&
                                  bookings.map((booking) => (
                                    <tr
                                      key={booking.id}
                                      className="hover:bg-gray-100"
                                    >
                                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                        {booking.booking_sn}
                                      </td>
                                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                        {mapName(booking.type)}
                                      </td>
                                      <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                                        <time dateTime={booking.date}>
                                          {booking.date}
                                        </time>
                                      </td>
                                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                        {booking.start_time.split(":")[0] +
                                          ":" +
                                          booking.start_time.split(":")[1]}
                                        -
                                        {parseInt(
                                          booking.start_time.split(":")[0]
                                        ) +
                                          booking.duration +
                                          ":00"}
                                      </td>{" "}
                                      <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                        <button
                                          className="ml-2 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded"
                                          onClick={() =>
                                            voidBooking(booking.booking_sn)
                                          }
                                        >
                                          VOID
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                {info.bookings.length === 0 && (
                                  <tr>
                                    <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                      No result
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {/* Transactions*/}
                <section aria-labelledby="applicant-information-title">
                  <div className="bg-white shadow sm:rounded-lg">
                    <div className="flex flex-col mt-8">
                      <div className="overflow-x-auto rounded-lg">
                        <div className="align-middle inline-block min-w-full">
                          <div className="shadow overflow-hidden sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Transaction
                                  </th>
                                  <th
                                    scope="col"
                                    className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Payment Method
                                  </th>
                                  <th
                                    scope="col"
                                    className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Amount
                                  </th>
                                  <th
                                    scope="col"
                                    className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Status
                                  </th>
                                  <th
                                    scope="col"
                                    className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Created At
                                  </th>
                                  <th
                                    scope="col"
                                    className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {info.transactions.length === 0 && (
                                  <tr>
                                    <td className="whitespace-nowrap px-6 py-4  text-gray-500">
                                      No result
                                    </td>
                                  </tr>
                                )}
                                {info.transactions.map((data) => (
                                  <tr
                                    key={data.id}
                                    className="transition duration-300 ease-in-out hover:bg-gray-100"
                                  >
                                    <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                                      {data.booking_sn ?? data.order_number}
                                    </td>
                                    <td className="p-4 whitespace-nowrap  font-normal text-gray-500">
                                      {data.payment_method_name}
                                    </td>
                                    <td className="p-4 whitespace-nowrap  font-semibold text-gray-900">
                                      $
                                      {data.amount
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </td>
                                    <td className="p-4 whitespace-nowrap  font-normal text-gray-500">
                                      {data.transaction_status}
                                    </td>
                                    <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                                      {moment(data.createdAt).format(
                                        "YYYY-MM-DD HH:mm"
                                      )}
                                    </td>
                                    <td className="p-4 whitespace-nowrap  font-semibold text-gray-900">
                                      {data.transaction_status == "unpaid" && (
                                        <>
                                          <button
                                            onClick={() =>
                                              confirmPayment(data.order_number)
                                            }
                                            className="bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded"
                                          >
                                            APPROVE
                                          </button>
                                          <button
                                            className="ml-2 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded"
                                            onClick={() =>
                                              rejectPayment(data.order_number)
                                            }
                                          >
                                            VOID
                                          </button>
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                {/* User Remaining Hour*/}
                <section aria-labelledby="applicant-information-title">
                  <div className="bg-white shadow sm:rounded-lg">
                    <div className="flex flex-col mt-8">
                      <div className="overflow-x-auto rounded-lg">
                        <div className="align-middle inline-block min-w-full">
                          <div className="shadow overflow-hidden sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th
                                    scope="col"
                                    className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Type
                                  </th>
                                  <th
                                    scope="col"
                                    className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Remaining Hour
                                  </th>
                                  <th
                                    scope="col"
                                    className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                  >
                                    Expiry Date
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-gray-200 bg-white">
                                {info.user_wallets.length === 0 && (
                                  <tr>
                                    <td className="whitespace-nowrap px-6 py-4  text-gray-500">
                                      No result
                                    </td>
                                  </tr>
                                )}
                                {info.user_wallets.map((data) =>
                                  data.type ==
                                    "practice,professional_recording" ||
                                  data.type == "practice,self_recording" ||
                                  data.type == "recital" ? (
                                    <></>
                                  ) : (
                                    <tr
                                      key={data.id}
                                      className="transition duration-300 ease-in-out hover:bg-gray-100"
                                    >
                                      <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                                        {mapName(data.type)}
                                      </td>
                                      <td className="p-4 whitespace-nowrap  font-normal text-gray-500">
                                        {data.remaining_hour}
                                      </td>
                                      <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                                        {moment(data.expired_at).format(
                                          "YYYY-MM-DD HH:mm"
                                        )}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>
      </>
    );
}
