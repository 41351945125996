import React from "react";

export const FailureWidget = () => {
  return (
    <section className="body-font p-16 bg-gray-50 pt-10 text-gray-600">
      <div className="container mx-auto mt-10 flex max-w-5xl flex-wrap justify-center rounded-lg bg-white px-5 py-24">
        <div className="flex-wrap md:flex">
          <div className="bg-white p-6  md:mx-auto">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              className="text-red-600 w-16 h-16 mx-auto my-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <div className="text-center">
              <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
                Payment Failure
              </h3>
              <p className="text-gray-600 my-2">
                Payment unsucessful, please try again later.
              </p>
              {/* <p> Have a great day! </p> */}
              <div className="py-10 text-center">
                <a
                  href="/account"
                  className="flex w-full justify-center rounded-md border border-transparent bg-yellow-600 p-4 text-md font-medium text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
                >
                  {/* 回到我的紀錄 */}
                  Back to My Transaction Record
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
