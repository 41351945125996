import "../App.css";
import React from "react";
import Layout from "./Layout";
import { motion } from "framer-motion";
import { AccountWidget } from "../components/AccountWidget";
const Account = () => {
  return (
    <Layout>
      <motion.div exit={{ opacity: 0 }}>
        <AccountWidget />
      </motion.div>
    </Layout>
  );
};

export default Account;
