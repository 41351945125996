import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import ContactUs from "../components/ContactUs";
import Layout from "./Layout";
const Contact = () => {
  const location = useLocation();

  return (
    <Layout>
      <motion.div exit={{ opacity: 0 }}>
        <div>
          <ContactUs />
        </div>
      </motion.div>
    </Layout>
  );
};

export default Contact;
