import { useSearchParams, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import useToken from "./useToken";
import { LoadingWidget } from "./LoadingWidget";

export default function AdminUpdateContent() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const { token } = useToken();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState("");

  //Form data
  const [name, setName] = useState("");
  const [eng_name, setEngName] = useState("");
  const [tnc, setTnc] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [durations, setDurations] = useState("");
  const [discount, setDiscount] = useState("");
  const [available, setAvailable] = useState();

  const fetchData = async () => {
    try {
      let id = searchParams.get("id");

      const response = await fetch(
        `https://studio-tableau-prd.onrender.com/api/admin/findItemByID/${id}`,
        {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            type: searchParams.get("type"),
          }),
        }
      );

      const string = await response.text();
      const json = string === "" ? {} : JSON.parse(string);
      console.log(json);
      setData(json.data);
      setName(json.data.name);
      setEngName(json.data.eng_name);
      setTnc(json.data.tnc);
      setDescription(json.data.description);
      setPrice(json.data.price);
      setDurations(json.data.durations);
      setDiscount(json.data.discount);
      setAvailable(json.data.available == 1 ? true : false);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const updateData = async () => {
    try {
      setSubmitting(true);
      let id = searchParams.get("id");
      let type = searchParams.get("type");
      if (type == "package") {
        const response = await fetch(
          `https://studio-tableau-prd.onrender.com/api/package/${id}`,
          {
            method: "put",
            headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              name: name,
              eng_name: eng_name,
              tnc: tnc,
              description: description,
              price: price,
              discount: discount,
              durations: durations,
              available: available,
            }),
          }
        );
        setSubmitting(false);
        navigate(-1);
      } else {
        var body = JSON.stringify({
          name: name,
          eng_name: eng_name,
          tnc: tnc,
          description: description,
          price: price,
          durations: durations,
          available: available,
        });
        console.log(body);
        const response = await fetch(
          `https://studio-tableau-prd.onrender.com/api/product/${id}`,
          {
            method: "put",
            headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: body,
          }
        );
        setSubmitting(false);
        navigate(-1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  if (loading) return <LoadingWidget />;
  else
    return (
      <div className="relative bg-gray-50">
        <div className="lg:max-w-7xl pl-4 pr-4 mx-auto">
          <br />
          <form className="space-y-8 divide-y divide-gray-200">
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                <div>
                  <h2 className="text-base text-lg font-semibold leading-6 text-gray-900">
                    Product Information
                  </h2>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Update product information here.
                  </p>
                </div>
                <div className="space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                    >
                      Name
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        onChange={(e) => setName(e.target.value)}
                        autoComplete="name"
                        defaultValue={data.name}
                        className="block p-2 w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="eng_name"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                    >
                      Purpose
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                      <input
                        type="text"
                        name="eng_name"
                        id="eng_name"
                        onChange={(e) => setEngName(e.target.value)}
                        defaultValue={data.eng_name}
                        autoComplete="eng_name"
                        className="block p-2 w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="tnc"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                    >
                      Terms and conditions
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                      <textarea
                        id="tnc"
                        name="tnc"
                        onChange={(e) => setTnc(e.target.value)}
                        rows={8}
                        defaultValue={data.tnc}
                        className="block p-2 w-full max-w-lg rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                      />
                      <p className="mt-2 text-sm text-gray-500" />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                    >
                      Description
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                      <textarea
                        id="description"
                        name="description"
                        onChange={(e) => setDescription(e.target.value)}
                        rows={8}
                        defaultValue={data.description}
                        className="block p-2 w-full max-w-lg rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                      />
                      <p className="mt-2 text-sm text-gray-500" />
                    </div>
                  </div>
                  {searchParams.get("type") == "product" && (
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="cover-photo"
                        className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                      >
                        Cover photo
                      </label>
                      <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <div className="flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                          <img src={data.img} />
                        </div>
                      </div>
                    </div>
                  )}

                  {searchParams.get("type") == "package" && (
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="discount"
                        className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                      >
                        Discount
                      </label>
                      <div className="mt-2 sm:col-span-2 sm:mt-0">
                        <input
                          type="number"
                          name="price"
                          id="price"
                          onChange={(e) => setDiscount(e.target.value)}
                          defaultValue={data.discount}
                          autoComplete="price"
                          className="block p-2 w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  )}

                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="price"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                    >
                      Price Per Hour
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                      <input
                        type="number"
                        name="price"
                        id="price"
                        onChange={(e) => setPrice(e.target.value)}
                        defaultValue={data.price}
                        autoComplete="price"
                        className="block p-2 w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="region"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                    >
                      Hours
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                      <input
                        type="number"
                        name="durations"
                        id="durations"
                        onChange={(e) => setDurations(e.target.value)}
                        defaultValue={data.durations}
                        autoComplete="durations"
                        className="block p-2 w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="available"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                    >
                      Display
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                      <select
                        id="available"
                        name="available"
                        autoComplete="available"
                        onChange={(e) => setAvailable(e.target.value)}
                        defaultValue={data.available ? 1 : 0}
                        className="block p-2 w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      >
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-5">
              {!submitting && (
                <div className="flex justify-end gap-x-3">
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Cancel
                  </button>

                  <button
                    type="submit"
                    onClick={updateData}
                    className="inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Save
                  </button>
                </div>
              )}
              {submitting && (
                <div className="flex justify-end gap-x-3">
                  <LoadingWidget />
                </div>
              )}
            </div>
            <br />
          </form>
        </div>
      </div>
    );
}
