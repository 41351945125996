import Layout from "./Layout";
import { motion } from "framer-motion";
import AdminUserDetails from "../components/AdminUserDetails";
export default function UserDetails() {
  return (
    <Layout>
      <motion.div exit={{ opacity: 0 }}>
        <AdminUserDetails />
      </motion.div>
    </Layout>
  );
}
