import React from "react";

export const PaymeWidget = (props) => {
  return (
    <section className="body-font h-screen bg-gray-50 pt-10 text-gray-600 mb-32">
      <div className="container mx-auto mt-10 flex max-w-5xl flex-wrap justify-center rounded-lg bg-white px-5 py-24">
        <div className="flex-wrap md:flex">
          <div className="mx-auto">
            <img
              src="./images/payme.webp"
              alt="payme"
              className="mx-auto mt-4 h-80 w-80  border p-2 md:mt-0"
            />
            <div>
              <br />
              <center>
                <a
                  className="mt-1 text-center text-sm text-blue-500"
                  href="https://payme.hsbc/studiotableau"
                >
                  https://payme.hsbc/studiotableau
                </a>
              </center>
              <p className="mt-1 text-center font-medium text-red-500">
                參考編號：{props.order_number}
              </p>
            </div>

            <a
              href={
                "https://wa.me/85251196273/?text=我想提交付款證明，我的訂單編號為" +
                props.order_number
              }
              target="_blank"
              rel="noreferrer"
              title="Contact Sale"
            >
              <div className="mx-auto w-52">
                <div className="m-4">
                  <div className="flex w-full items-center justify-center">
                    <label className="flex h-14 w-full cursor-pointer flex-col border-4 border-dashed border-gray-200 hover:border-gray-300 hover:bg-gray-100">
                      <div className="mt-4 flex items-center justify-center space-x-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="h-6 w-6 text-gray-400"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                          />
                        </svg>

                        <p className="font-laonoto text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                          提交付款證明
                        </p>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </a>
            {/* <a
              href="https://wa.me/85251196273/?text=我想提交付款證明，我的訂單編號為"
              title="Contact Sale"
              className="mx-auto block inline-flex px-6 py-2  justify-center rounded-md border border-transparent bg-gray-800 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2"
            >
              提交付款證明
            </a> */}
          </div>

          <div className="mt-8 max-w-sm md:mt-0 md:ml-10 md:w-2/3">
            <div className="relative flex pb-12">
              <div className="absolute inset-0 flex h-full w-10 items-center justify-center">
                <div className="pointer-events-none h-full w-1 bg-gray-200"></div>
              </div>
              <div className="relative z-10 inline-flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-[#FCF1ED] text-[#796B5F]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15"
                  />
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h2 className="title-font mb-1 text-sm font-medium tracking-wider text-gray-900">
                  STEP 1
                </h2>
                <p className="font-laonoto leading-relaxed">
                  請於Payme付款 <b>(${props.price}) </b>
                  後螢幕截圖，並點撃“提交付款證明”至我們的Whatsapp。
                </p>
              </div>
            </div>
            {/* <div className="relative flex pb-12">
              <div className="absolute inset-0 flex h-full w-10 items-center justify-center">
                <div className="pointer-events-none h-full w-1 bg-gray-200"></div>
              </div>
              <div className="relative z-10 inline-flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-[#FCF1ED] text-white">
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  className="h-5 w-5"
                  viewBox="0 0 24 24"
                >
                  <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h2 className="title-font mb-1 text-sm font-medium tracking-wider text-gray-900">
                  STEP 2
                </h2>
                <p className="font-laonoto leading-relaxed">
                  訂單確認後，將會收到Email通知。
                </p>
              </div>
            </div> */}
            <div className="relative flex pb-12">
              <div className="relative z-10 inline-flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-[#FCF1ED] text-[#796B5F]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                  />
                </svg>
              </div>
              <div className="flex-grow pl-4">
                <h2 className="title-font mb-1 text-sm font-medium tracking-wider text-gray-900">
                  STEP 2
                </h2>
                <p className="font-laonoto leading-relaxed">
                  管理員一經確認訂單後，您將會收到Email通知，並可以在Account頁面查看收據。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  // return (
  //   <div className="bg-white">
  //     <div className="mx-auto max-w-2xl px-4 pt-16 pb-24 sm:px-6 lg:max-w-7xl lg:px-8">
  //       <center>
  //         <img
  //           src="./images/payme.jpeg"
  //           alt="payme"
  //           className="h-96 flex-none object-cover object-center"
  //         />
  //         <a
  //           href="https://wa.me/85251196273/?text=我想提交付款證明，我的訂單編號為"
  //           title="Contact Sale"
  //           className="inline-flex justify-center rounded-md border border-transparent bg-gray-800 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2"
  //         >
  //           提交付款證明
  //         </a>
  //         <br />
  //         <br />
  //         請於Payme付款後螢幕截圖，並點撃以上“提交付款證明”至Whatsapp，我們將於需時7個工作天處理。
  //         <br />
  //         <br />
  //         以Payme或FPS轉數快方式付款並不能確保。如入數訂購後我們通知無貨，3天內退回客人入數款項。
  //       </center>
  //     </div>
  //   </div>
  // );
};
