import React, { useEffect, useState } from "react";
import { LoadingWidget } from "./LoadingWidget";
import useToken from "../components/useToken";
import { useNavigate } from "react-router-dom";

import moment from "moment";
export const AdminTransaction = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [searchTransaction, setSearchTransaction] = useState("");
  const [searchPendingTransaction, setSearchPendingTransaction] = useState("");

  const [totalItems, setTotalItems] = useState();
  const [totalPendingItems, setTotalPendingItems] = useState();
  const [transactionList, setTransactionsList] = useState([]);
  const [pendingTransactionList, setPendingTransactionsList] = useState([]);

  // transaction list
  const [currentTransactionPage, setCurrentTransactionPage] = useState(0);
  const [totalTransactionPage, setTotalTransactionPage] = useState(0);

  const [currentPendingPage, setCurrentPendingPage] = useState(0);
  const [totalPendingnPage, setTotalPendingPage] = useState(0);

  const { token } = useToken();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (page) => {
    try {
      const response = await fetch(
        "https://studio-tableau-prd.onrender.com/api/admin/getAllTransactions",
        {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ page: page ?? 0 }),
        }
      );
      const string = await response.text();
      const json = string === "" ? {} : JSON.parse(string);
      const response1 = await fetch(
        "https://studio-tableau-prd.onrender.com/api/admin/getAllPendingTransactions",
        {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ page: page ?? 0 }),
        }
      );
      const string1 = await response1.text();
      const json1 = string1 === "" ? {} : JSON.parse(string1);

      setTotalItems(json.totalItems);
      setTransactionsList(json.rows);
      setCurrentTransactionPage(json.currentPage);
      setTotalTransactionPage(json.totalPages);

      setTotalPendingItems(json1.totalItems);
      setPendingTransactionsList(json1.rows);
      setCurrentPendingPage(json1.currentPage);
      setTotalPendingPage(json1.totalPages);

      setLoading(false);
    } catch (error) {
      console.log(error);
      // Retry only if the error status code is 404
      if (error.response.status === 404) {
        console.log("Retrying...");
        await fetchData();
      }
    }
  };

  const confirmPayment = async (orderNumber) => {
    try {
      let body = JSON.stringify({ orderNumber: orderNumber });
      await fetch(
        `https://studio-tableau-prd.onrender.com/api/payment/confirmPayment`,
        {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: body,
        }
      );
      setPendingTransactionsList(
        pendingTransactionList.filter(
          (item) => item.order_number !== orderNumber
        )
      );
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const searchPendingTransactionID = async (event) => {
    try {
      event.preventDefault();
      const response = await fetch(
        "https://studio-tableau-prd.onrender.com/api/admin/getPendingTransactions/" +
          searchPendingTransaction,
        {
          method: "get",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const string = await response.text();
      const json = string === "" ? {} : JSON.parse(string);
      setPendingTransactionsList(json.data);
    } catch (error) {
      console.log(error);
    }
  };

  const searchTransactionID = async (event) => {
    try {
      event.preventDefault();
      const response = await fetch(
        "https://studio-tableau-prd.onrender.com/api/admin/getTransactions/" +
          searchTransaction,
        {
          method: "get",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const string = await response.text();
      const json = string === "" ? {} : JSON.parse(string);

      setTransactionsList(json.data);
    } catch (error) {
      console.log(error);
    }
  };

  const rejectPayment = async (orderNumber) => {
    try {
      let body = JSON.stringify({ orderNumber: orderNumber });
      await fetch(
        `https://studio-tableau-prd.onrender.com/api/payment/rejectPayment`,
        {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: body,
        }
      );
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const previousPendingPage = async () => {
    try {
      if (currentPendingPage != 0) {
        setCurrentPendingPage(currentPendingPage - 1);
        fetchData(currentPendingPage - 1);
        window.scrollTo(0, 150);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const nextPendingPage = async () => {
    try {
      if (currentPendingPage < totalPendingnPage) {
        fetchData(currentPendingPage + 1);
        setCurrentPendingPage(currentPendingPage + 1);
        window.scrollTo(0, 150);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const previousTransactionPage = async () => {
    try {
      if (currentTransactionPage != 0) {
        setCurrentTransactionPage(currentTransactionPage - 1);
        fetchData(currentTransactionPage - 1);
        window.scrollTo(0, 150);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const nextTransactionPage = async () => {
    try {
      if (currentTransactionPage < totalTransactionPage) {
        fetchData(currentTransactionPage + 1);
        setCurrentTransactionPage(currentTransactionPage + 1);
        window.scrollTo(0, 150);
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) return <LoadingWidget />;
  else
    return (
      <div>
        <br />
        <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
          <div className="mb-4 md:flex items-center justify-between">
            <div>
              <h3 className="text-xl font-bold text-gray-900 mb-2">
                All Pending for Approval Records ({totalPendingItems})
              </h3>
              <span className="text-base font-normal text-gray-500">
                This is a list of all pending for approval transactions record,
                include package and bookings created using Payme/ FPS
              </span>
            </div>

            <div className="flex w-100">
              <div className="flex-shrink-0">
                <div className="pt-2 relative mx-auto text-gray-600">
                  <form
                    className="space-y-6"
                    onSubmit={setSearchPendingTransaction}
                  >
                    <input
                      className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg  focus:outline-none"
                      type="search"
                      name="search"
                      placeholder="Search"
                      onChange={(e) =>
                        setSearchPendingTransaction(e.target.value)
                      }
                    />
                    <button
                      type="submit"
                      onClick={searchPendingTransactionID}
                      onKeyDown={searchPendingTransactionID}
                      className="absolute right-0 top-0 mt-5 mr-4"
                    >
                      <svg
                        className="text-gray-600 h-4 w-4 fill-current"
                        id="Capa_1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 56.966 56.966"
                        width="512px"
                        height="512px"
                      >
                        <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                      </svg>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-8">
            <div className="overflow-x-auto rounded-lg">
              <div className="align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Order Number
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Payment Method
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Amount
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Created At
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {pendingTransactionList.map((data) => (
                        <tr
                          key={data.id}
                          className="transition duration-300 ease-in-out hover:bg-gray-100"
                        >
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            {data.booking_sn ?? data.order_number}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-500">
                            {data.payment_method_name}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-semibold text-gray-900">
                            $
                            {data.amount
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            {moment(data.createdAt).format("YYYY-MM-DD HH:mm")}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-semibold text-gray-900">
                            <button
                              onClick={() => confirmPayment(data.order_number)}
                              className="bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded"
                            >
                              CONFIRM
                            </button>
                            <button
                              className="ml-2 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded"
                              onClick={() => rejectPayment(data.order_number)}
                            >
                              VOID
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="flex">
              {currentPendingPage != 0 &&
                currentPendingPage < totalPendingnPage && (
                  <button
                    onClick={previousPendingPage}
                    className="inline-flex items-center px-4 py-2 ml-3  font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                  >
                    Back
                  </button>
                )}
              {currentPendingPage + 1 < totalPendingnPage && (
                <>
                  <div className="grow h-14 ..."></div>
                  <button
                    href="#"
                    onClick={nextPendingPage}
                    className="inline-flex items-center px-4 py-2 ml-3  font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                  >
                    Next
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        <br />
        <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
          <div className="mb-4 md:flex items-center justify-between">
            <div>
              <h3 className="text-xl font-bold text-gray-900 mb-2">
                All Paid Transactions ({totalItems})
              </h3>
              <span className="text-base font-normal text-gray-500">
                This is a list of all paid transactions record, including
                package and bookings
              </span>
            </div>

            <div className="flex w-100">
              <div className="flex-shrink-0">
                <div className="pt-2 relative mx-auto text-gray-600">
                  <form className="space-y-6" onSubmit={setSearchTransaction}>
                    <input
                      className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg  focus:outline-none"
                      type="search"
                      name="search"
                      placeholder="Search"
                      onChange={(e) => setSearchTransaction(e.target.value)}
                    />
                    <button
                      type="submit"
                      onClick={searchTransactionID}
                      onKeyDown={searchTransactionID}
                      className="absolute right-0 top-0 mt-5 mr-4"
                    >
                      <svg
                        className="text-gray-600 h-4 w-4 fill-current"
                        id="Capa_1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 56.966 56.966"
                        width="512px"
                        height="512px"
                      >
                        <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                      </svg>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-8">
            <div className="overflow-x-auto rounded-lg">
              <div className="align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Order Number
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Payment Method
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Amount
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Created At
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Invoice
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {transactionList.map((data) => (
                        <tr
                          key={data.id}
                          className="transition duration-300 ease-in-out hover:bg-gray-100"
                        >
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            {data.booking_sn ?? data.order_number}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-500">
                            {data.payment_method_name}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-semibold text-gray-900">
                            $
                            {data.amount
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            {moment(data.createdAt).format("YYYY-MM-DD HH:mm")}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            <button
                              className="ml-2 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded"
                              onClick={() => rejectPayment(data.order_number)}
                            >
                              VOID
                            </button>
                            <button
                              className="ml-2 bg-transparent hover:bg-purple-500 text-purple-700 font-semibold hover:text-white py-2 px-4 border border-purple-500 hover:border-transparent rounded"
                              onClick={() =>
                                navigate("/invoice/" + data.order_number)
                              }
                            >
                              RECEIPT
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="flex">
              {currentTransactionPage != 0 &&
                currentTransactionPage < totalTransactionPage && (
                  <button
                    onClick={previousTransactionPage}
                    className="inline-flex items-center px-4 py-2 ml-3  font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                  >
                    Back
                  </button>
                )}
              {currentTransactionPage + 1 < totalTransactionPage && (
                <>
                  <div className="grow h-14 ..."></div>
                  <button
                    href="#"
                    onClick={nextTransactionPage}
                    className="inline-flex items-center px-4 py-2 ml-3  font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                  >
                    Next
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        <br />
      </div>
    );
};
