import React from "react";
import GoogleMapReact from "google-map-react";

export default function ContactUs() {
  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: { lat: 22.314238627582615, lng: 114.21921741387669 },
      map,
      title: "Address",
    });
    return marker;
  };

  const defaultProps = {
    center: {
      lat: 22.314238627582615,
      lng: 114.21921741387669,
    },
    zoom: 18,
  };
  return (
    <center>
      {" "}
      <header>
        <div>
          <img
            className="w-full h-96 object-cover"
            src="./images/contact.webp"
          />
        </div>
      </header>
      <div className="max-w-7xl">
        <div className="mx-auto md:flex content-center items-stretch justify-items-stretch place-items-stretch max-w-md mt-12 px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="flex-1 bg-gray-50 m-4">
            <p className="text-left py-4 mt-4 text-lg leading-6 text-black text-center">
              Email:{" "}
              <a
                href="mailto: contact@studiotableau.org"
                className="text-yellow-600"
              >
                contact@studiotableau.org
              </a>
              <br />
              <br />
              Whatsapp:{" "}
              <a className="text-yellow-600" href="https://wa.me/85251196273/">
                5119 6273
              </a>{" "}
              <br />
              <br />
              Address: <br />
              Room 2303, 23/F, Ricky Center, <br />
              36 Chong Yip Street, Kwun Tong, <br />
              Kowloon, Hong Kong <br />
              <br />
              Opening hours: 9:00-23:00
            </p>
          </div>
          <div style={{ height: 400, width: "100%" }} className="flex-1 m-4">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyAmWNR4a5mmFvJVgaqzq5evomZ5IXPRyyQ",
              }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
            ></GoogleMapReact>
          </div>
        </div>
      </div>
    </center>
  );
}
