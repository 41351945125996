import React, { useEffect, useState, Fragment } from "react";
import { LoadingWidget } from "./LoadingWidget";
import { Dialog, Transition } from "@headlessui/react";
import useToken from "../components/useToken";
import { useNavigate } from "react-router-dom";
import moment from "moment";
export const AdminContentList = () => {
  const [loading, setLoading] = useState(true);
  const [bookingList, setBookingList] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [showConfirmation1, setShowConfirmation1] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { token } = useToken();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://studio-tableau-prd.onrender.com/api/admin/getAllProductContent",
          {
            method: "get",
            headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const string = await response.text();

        const json = string === "" ? {} : JSON.parse(string);
        console.log(json);
        setPackageList(json.packages);
        setBookingList(json.products);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleDelete = async (id, type) => {
    try {
      console.log(type);
      if (type == "product") {
        await fetch(
          `https://studio-tableau-prd.onrender.com/api/product/${id}`,
          {
            method: "delete",
            headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setBookingList(bookingList.filter((item) => item.id !== id));
      } else {
        await fetch(
          `https://studio-tableau-prd.onrender.com/api/package/${id}`,
          {
            method: "delete",
            headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPackageList(packageList.filter((item) => item.id !== id));
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) return <LoadingWidget />;
  else
    return (
      <div>
        <br />
        <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
          <div className="mb-4 flex items-center justify-between">
            <div>
              <h3 className="text-xl font-bold text-gray-900 mb-2">
                All Booking Products ({bookingList.length})
              </h3>
              <span className="text-base font-normal text-gray-500">
                This is a list of all bookings products
              </span>
            </div>
            {/* <div className="flex-shrink-0">
              <div className="pt-2 relative mx-auto text-gray-600">
                <input
                  className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg  focus:outline-none"
                  type="search"
                  name="search"
                  placeholder="Search"
                />
                <button type="submit" className="absolute right-0 top-0 mt-5 mr-4">
                  <svg
                    className="text-gray-600 h-4 w-4 fill-current"
                    id="Capa_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 56.966 56.966"
                    width="512px"
                    height="512px"
                  >
                    <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                  </svg>
                </button>
              </div>
            </div> */}
          </div>
          <div className="flex flex-col mt-8">
            <div className="overflow-x-auto rounded-lg">
              <div className="align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          ID
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Price
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Durations
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Created At
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {bookingList.map((data) => (
                        <tr
                          key={data.id}
                          className="transition duration-300 ease-in-out hover:bg-gray-100"
                        >
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            {data.id}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            {data.name}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-500">
                            $
                            {data.price
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            {data.durations}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            {moment(data.created_at).format("YYYY-MM-DD HH:mm")}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            <button
                              onClick={() =>
                                navigate(
                                  `/update_content?type=product&id=${data.id}`
                                )
                              }
                              className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                            >
                              EDIT
                            </button>
                            <button
                              className="ml-2 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded"
                              onClick={() => setShowConfirmation1(true)}
                            >
                              DELETE
                            </button>
                            {showConfirmation1 && (
                              <Transition.Root
                                show={showConfirmation1}
                                as={Fragment}
                              >
                                <Dialog
                                  as="div"
                                  className="relative z-10"
                                  onClose={setShowConfirmation1}
                                >
                                  <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <div className="fixed inset-0 bg-gray-500 bg-opacity-20 transition-opacity" />
                                  </Transition.Child>

                                  <div className="fixed inset-0 z-10 overflow-y-auto">
                                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                      <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                      >
                                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                          <div>
                                            <div className="mt-3 text-center sm:mt-5">
                                              <Dialog.Title
                                                as="h3"
                                                className="text-base font-semibold leading-6 text-gray-900"
                                              >
                                                Warning
                                              </Dialog.Title>
                                              <div className="mt-2">
                                                <p className=" text-gray-500">
                                                  Are you sure you want to
                                                  delete this item?
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="mt-5 sm:mt-6">
                                            <button
                                              type="button"
                                              className="inline-flex justify-center px-4 py-2  font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-2"
                                              onClick={() =>
                                                setShowConfirmation1(false)
                                              }
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              type="button"
                                              className="inline-flex justify-center px-4 py-2  font-medium text-white bg-red-500 border border-transparent rounded-md shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                              onClick={() => {
                                                setShowConfirmation1(false);
                                                handleDelete(
                                                  data.id,
                                                  "product"
                                                );
                                              }}
                                            >
                                              Delete
                                            </button>
                                          </div>
                                        </Dialog.Panel>
                                      </Transition.Child>
                                    </div>
                                  </div>
                                </Dialog>
                              </Transition.Root>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
          <div className="mb-4 flex items-center justify-between">
            <div>
              <h3 className="text-xl font-bold text-gray-900 mb-2">
                All Packages ({packageList.length})
              </h3>
              <span className="text-base font-normal text-gray-500">
                This is a list of all bookings products
              </span>
            </div>
            {/* <div className="flex-shrink-0">
              <div className="flex-shrink-0">
                <div className="pt-2 relative mx-auto text-gray-600">
                  <input
                    className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg  focus:outline-none"
                    type="search"
                    name="search"
                    placeholder="Search"
                  />
                  <button
                    type="submit"
                    className="absolute right-0 top-0 mt-5 mr-4"
                  >
                    <svg
                      className="text-gray-600 h-4 w-4 fill-current"
                      id="Capa_1"
                      x="0px"
                      y="0px"
                      viewBox="0 0 56.966 56.966"
                      width="512px"
                      height="512px"
                    >
                      <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div> */}
          </div>
          <div className="flex flex-col mt-8">
            <div className="overflow-x-auto rounded-lg">
              <div className="align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          ID
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Price
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Durations
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Created At
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {packageList.map((data) => (
                        <tr
                          key={data.id}
                          className="transition duration-300 ease-in-out hover:bg-gray-100"
                        >
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            {data.id}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            {data.name}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-500">
                            $
                            {data.price
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            {data.durations}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            {moment(data.createdAt).format("YYYY-MM-DD HH:mm")}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            <button
                              onClick={() =>
                                navigate(
                                  `/update_content?type=package&id=${data.id}`
                                )
                              }
                              className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                            >
                              EDIT
                            </button>
                            <button
                              className="ml-2 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded"
                              onClick={() => setShowConfirmation(true)}
                            >
                              DELETE
                            </button>
                            {showConfirmation && (
                              <Transition.Root
                                show={showConfirmation}
                                as={Fragment}
                              >
                                <Dialog
                                  as="div"
                                  className="relative z-10"
                                  onClose={setShowConfirmation}
                                >
                                  <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <div className="fixed inset-0 bg-gray-500 bg-opacity-20 transition-opacity" />
                                  </Transition.Child>

                                  <div className="fixed inset-0 z-10 overflow-y-auto">
                                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                      <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                      >
                                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                          <div>
                                            <div className="mt-3 text-center sm:mt-5">
                                              <Dialog.Title
                                                as="h3"
                                                className="text-base font-semibold leading-6 text-gray-900"
                                              >
                                                Warning
                                              </Dialog.Title>
                                              <div className="mt-2">
                                                <p className=" text-gray-500">
                                                  Are you sure you want to
                                                  delete this item?
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="mt-5 sm:mt-6">
                                            <button
                                              type="button"
                                              className="inline-flex justify-center px-4 py-2  font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-2"
                                              onClick={() =>
                                                setShowConfirmation(false)
                                              }
                                            >
                                              Cancel
                                            </button>
                                            <button
                                              type="button"
                                              className="inline-flex justify-center px-4 py-2  font-medium text-white bg-red-500 border border-transparent rounded-md shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                              onClick={() => {
                                                setShowConfirmation(false);
                                                handleDelete(
                                                  data.id,
                                                  "package"
                                                );
                                              }}
                                            >
                                              Delete
                                            </button>
                                          </div>
                                        </Dialog.Panel>
                                      </Transition.Child>
                                    </div>
                                  </div>
                                </Dialog>
                              </Transition.Root>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    );
};
