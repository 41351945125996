/* This example requires Tailwind CSS v2.0+ */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function PackageWidget() {
  const [packages, setPackages] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setPackages([]);
      const response = await fetch(
        "https://studio-tableau-prd.onrender.com/api/package",
        {
          method: "get",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
        }
      );
      const string = await response.text();

      const json = string === "" ? {} : JSON.parse(string);
      console.log(json);
      setPackages(json);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="bg-white">
      <header>
        <div>
          <LazyLoadImage
            className="w-full h-96 object-cover"
            src="./images/piano.webp"
          />
        </div>
      </header>
      <div className=" mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8 ">
        <div className="space-y-12">
          <center>
            <h3 className="text-3xl tracking-tight sm:text-4xl">
              Packages / Discount
            </h3>
          </center>
          <ul
            duration="list"
            className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8"
          >
            {packages.map((product) => (
              <li key={product.id}>
                <div className="space-y-4 border border-amber-800 px-6 py-5  text-center">
                  <div className="aspect-w-3 aspect-h-2">
                    {/* <img
                      className="rounded-lg object-cover shadow-lg"
                      src={product.price}
                      alt=""
                    /> */}
                  </div>

                  <div className="space-y-2">
                    <div className="space-y-1 text-lg font-medium leading-6">
                      <h3>{product.name}</h3>
                      <h3 className=" p-2">{product.eng_name}</h3>
                      <Link
                        to={"/packageInfo/" + product.id}
                        state={{
                          product: product,
                          // selected: selected,
                          // time: time,
                          // duration: duration,
                        }}
                      >
                        <i className="underline text-[#c5c5c5]">More</i>
                      </Link>
                      <br />
                      <div className="relative p-2">
                        <div
                          className="absolute inset-0 flex items-center"
                          aria-hidden="true"
                        >
                          <div className="w-full border-t border-gray-300" />
                        </div>
                      </div>
                      <span className=" p-2">
                        {product.durations > 1
                          ? product.durations + " Hours"
                          : product.durations + " Hour"}
                      </span>
                      <br />$
                      {product.price.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      }) + " "}
                      ($
                      {product.discount.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}{" "}
                      off)
                      <br /> <br />
                      <Link
                        to="/cart"
                        state={{
                          product: product,
                          // selected: selected,
                          // time: time,
                          // duration: duration,
                        }}
                      >
                        <button className="bg-[#FCF1ED] hover:[#C6816A] text-[#9B9188] py-1 px-12 rounded-full">
                          Book Now
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
