import "../App.css";
import React from "react";
// import CheckOutWidget from "../components/Checkout";
import Layout from "./Layout";
import { motion } from "framer-motion";
function Checkout() {
  return (
    <Layout>
      <motion.div exit={{ opacity: 0 }}>{/* <CheckOutWidget /> */}</motion.div>
    </Layout>
  );
}

export default Checkout;
