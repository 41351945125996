import React from "react";
import AdminUpdateContent from "../components/AdminUpdateContent";

import Layout from "./Layout";
import { motion } from "framer-motion";
export const UpdateContent = () => {
  return (
    <Layout>
      <motion.div exit={{ opacity: 0 }}>
        <AdminUpdateContent />
      </motion.div>
    </Layout>
  );
};
