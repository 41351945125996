import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Alert from "./Alert";
export const ForgetPasswordWidget = () => {
  const [email, setEmail] = useState("");
  const [alert, setAlert] = useState(false);
  const [errText, setText] = useState();
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setAlert(true);
      setText("Please input email and password.");
    } else {
      try {
        console.log("called");
        let body = JSON.stringify({
          email: email,
        });
        const response = await fetch(
          "https://studio-tableau-prd.onrender.com/api/auth/forgetPassword",
          {
            method: "post",
            headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
            },
            body: body,
          }
        );
        const string = await response.text();

        const json = string === "" ? {} : JSON.parse(string);
        console.log(json);
        if (json.result) {
          navigate("/");
        } else {
          setEmail("");
          setAlert(true);
          setText("User does not exist.");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <div className="bg-gray-50">
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-lg">
          <div className=" my-10 bg-white p-8 rounded-xl shadow shadow-slate-300">
            <h1 className="text-4xl font-medium">Forget password</h1>
            <p className="text-slate-500">
              Fill up the form to reset the password
            </p>

            {alert && (
              <div>
                <br />
                <Alert title="Error" text={errText} />
              </div>
            )}
            <form onSubmit={handleSubmit} className="my-10">
              <div className="flex flex-col space-y-5">
                <label for="email">
                  <p className="font-medium text-slate-700 pb-2">
                    Email address
                  </p>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    autoFocus
                    className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow"
                    placeholder="Enter email address"
                  />
                </label>

                <button
                  className="flex w-full justify-center rounded-md border border-transparent bg-yellow-600 py-2 px-4 text-md font-medium text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
                  onClick={handleSubmit}
                  onKeyDown={handleSubmit}
                >
                  <span>Reset password</span>
                </button>
                <p className="text-center">
                  Not registered yet?{" "}
                  <Link
                    to="/register"
                    className="text-yellow-600 font-medium inline-flex space-x-1 items-center"
                  >
                    <span> Register now </span>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                        />
                      </svg>
                    </span>
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
