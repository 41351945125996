import React from "react";
import { useState } from "react";
import { LogoutIcon, CreditCardIcon, KeyIcon } from "@heroicons/react/outline";
import { MyTransaction } from "../pages/MyTransaction";
import { ResetWidget } from "./ResetWidget";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const subNavigation = [
  {
    name: "My Account",
    href: "#",
    index: 1,
    icon: CreditCardIcon,
    current: false,
  },
  { name: "Password", href: "#", index: 2, icon: KeyIcon, current: false },
  {
    name: "Logout",
    href: "/logout",
    index: 3,
    icon: LogoutIcon,
    current: true,
  },
];

export const AccountWidget = () => {
  const [currentIndex, setCurrentIndex] = useState(1);

  return (
    <div className="bg-gray-50 ">
      <main className="mx-auto max-w-7xl pb-10 lg:py-12 lg:px-8">
        <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
          <aside className="py-6 px-2 sm:px-6 lg:col-span-3 lg:py-0 lg:px-0">
            <nav className="space-y-1">
              {subNavigation.map((item) => (
                <a
                  key={item.name}
                  onClick={() => setCurrentIndex(item.index)}
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-50 text-orange-600 hover:bg-white"
                      : "text-gray-900 hover:text-gray-900 hover:bg-gray-50",
                    "group rounded-md px-3 py-2 flex items-center text-sm font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  <item.icon
                    className={classNames(
                      item.current
                        ? "text-orange-500"
                        : "text-gray-400 group-hover:text-gray-500",
                      "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  <span className="truncate">{item.name}</span>
                </a>
              ))}
            </nav>
          </aside>
          {currentIndex === 1 && <MyTransaction />}
          {currentIndex === 2 && <ResetWidget />}
        </div>
      </main>
    </div>
  );
};
