import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { useNavigate } from "react-router-dom";
import useToken from "../components/useToken";
import { LoadingWidget } from "./LoadingWidget";
import Alert from "./Alert";
const DatePicker = ({ product }) => {
  var today = new Date();
  var toDate = new Date(new Date().setMonth(new Date().getMonth() + 3));

  const css = `
    .my-selected:not([disabled]) {
      font-weight: bold;
      // border: 2px solid currentColor;
    }
    .my-selected:hover:not([disabled]) {
      border-color: blue;
      color: blue;
    }
    .my-today {
      font-weight: bold;

      color:white;
      background-color: #a45252;
    }

  `;

  const navigate = useNavigate();
  const { token } = useToken();

  const [loading, setLoading] = useState(false);
  const [loadingTimeslots, setLoadingTimeslots] = useState(true);
  const [alert, setAlert] = useState(false);
  const [errText, setText] = useState();

  const [userRemaining, setUserRemaining] = useState();

  const [selected, setSelected] = useState(today);
  const [time, setTime] = useState();

  const [duration, setDuration] = useState(product.durations);
  const [timeslots, setTimeslots] = useState([]);

  const [numberArray, setNumberArray] = useState([]);

  let footer = (
    <b>
      <center>Please pick a day.</center>
    </b>
  );
  if (selected) {
    footer = (
      <b>
        <center>
          <br></br>
          <p>
            You picked
            {" " +
              format(selected, "PP") +
              (time == null
                ? ""
                : ", " + time.toString().padStart(2, "0") + ":00")}
          </p>
        </center>
      </b>
    );
    console.log(selected);
  }

  useEffect(() => {
    initData();
    var temp = [];
    for (var i = product.durations; i <= 14; i += product.durations) {
      temp.push(i);
    }
    setNumberArray(temp);
  }, []);

  const fetchData = async (hour, date) => {
    try {
      setLoadingTimeslots(true);
      setTimeslots([]);
      // var date = format(selected, "yyyy-MM-dd");
      // console.log(duration);
      var body = JSON.stringify({
        date: format(date, "yyyy-MM-dd"),
        durations: hour,
        roomBookingType: 1, // product table id
      });

      const response = await fetch(
        "https://studio-tableau-prd.onrender.com/api/calendar/availableTimeslot",
        {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: body,
        }
      );

      const string = await response.text();
      const json = string === "" ? {} : JSON.parse(string);
      console.log(json);
      setTimeslots(json.available_time ?? []);
      setLoadingTimeslots(false);
    } catch (error) {
      setLoadingTimeslots(false);
      console.log(error);
      // fetchData(hour, date);
    }
  };

  const initData = async () => {
    try {
      setLoadingTimeslots(true);
      setTimeslots([]);

      var body = JSON.stringify({
        date: format(selected, "yyyy-MM-dd"),
        durations: duration,
        roomBookingType: 1, // product table id
      });
      console.log(body);
      const response = await fetch(
        "https://studio-tableau-prd.onrender.com/api/calendar/availableTimeslot",
        {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          body: body,
        }
      );
      const string = await response.text();
      const json = string === "" ? {} : JSON.parse(string);

      setTimeslots(json.available_time ?? []);
      setLoadingTimeslots(false);
    } catch (error) {
      console.log(error);
      // initData();
    }
  };

  const changeDuration = async (hour) => {
    setDuration(hour);
    console.log("N:" + hour);
    fetchData(hour, selected);
  };

  const changeSelected = (date) => {
    console.log("n" + date);
    setSelected(date);
    fetchData(duration, date);
  };

  const checkout = async (product) => {
    console.log(token);

    try {
      setAlert(false);
      var body = JSON.stringify({
        date: format(selected, "yyyy-MM-dd"),
        item_type: "product",
        item_id: product.id,
        payment_method_id: 1,
        time: time,
        durations: duration,
        // quantity: 1,
      });

      const response = await fetch(
        "https://studio-tableau-prd.onrender.com/api/payment/checkout",
        {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: body,
        }
      );
      const string = await response.text();
      const json = string === "" ? {} : JSON.parse(string);

      if (json.paymentLink) {
        window.location.replace(json.paymentLink);
        setLoading(false);
      } else {
        setAlert(true);
        setLoading(false);
        setText("Your selected timeslot is no longer available.");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      // checkout(product);
    }
    // } else if (selectedMethod == 2) {
    //   navigate("/payme");
    // } else if (selectedMethod == 3) {
    //   navigate("/fps");
    // }
  };

  const getUserRemainingHours = async (product) => {
    try {
      if (!time || !selected) {
        setAlert(true);
        setText("Please input all required data.");
      } else if (token) {
        setLoading(true);
        setAlert(false);
        setUserRemaining();
        const response = await fetch(
          "https://studio-tableau-prd.onrender.com/api/calendar/getUserRemainingHours",
          {
            method: "post",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              roomBookingType: product.id,
              duration: duration,
            }),
          }
        );
        const string = await response.text();
        const json = string === "" ? {} : JSON.parse(string);

        setUserRemaining(json.remaining_hour);

        var body = JSON.stringify({
          date: format(selected, "yyyy-MM-dd"),
          item_type: "product",
          item_id: product.id,
          time: time,
          durations: duration,
          // quantity: 1,
        });

        const response1 = await fetch(
          "https://studio-tableau-prd.onrender.com/api/payment/holdTime",
          {
            method: "post",
            headers: {
              Accept: "application/json, text/plain, */*",
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
            body: body,
          }
        );
        const string1 = await response1.text();
        const json1 = string1 === "" ? {} : JSON.parse(string1);
        if (!json1.result) {
          setAlert(true);
          setLoading(false);
          setText("Your selected timeslot is no longer available.");
        } else {
          if (json.remaining_hour - duration >= 0) {
            navigate("/confirm_page", {
              state: {
                product: product,
                time: time,
                selected: selected,
                duration: duration,
                order_number: json1.order_number,
                booking_sn: json1.booking_sn,
              },
            });
          } else {
            navigate("/confirm_payment_only_page", {
              state: {
                product: product,
                time: time,
                selected: selected,
                duration: duration,
                order_number: json1.order_number,
                booking_sn: json1.booking_sn,
              },
            });
            // checkout(product);
          }
        }
      }
    } catch (error) {
      console.log(error);
      // getUserRemainingHours(product);
    }
  };

  const handleTime = (timeslot) => {
    setTime(timeslot);
  };

  return (
    <div className="bg-gray-50">
      <div>
        <div className="relative isolate overflow-hidden bg-gray-900">
          <div className="py-24 px-6 sm:px-6 sm:py-32 lg:px-8">
            <div className="mx-auto max-w-2xl text-center">
              <h2 className="text-4xl font-bold tracking-tight text-white">
                {product.name}
              </h2>
              <br />
              <h2 className="text-3xl font-bold tracking-tight text-white">
                {product.eng_name}
              </h2>
              <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">
                {product.durations > 1
                  ? product.durations + " Hours"
                  : product.durations + " Hour"}{" "}
                ($
                {product.durations * product.price})
              </p>
            </div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1024 1024"
            className="absolute top-1/2 left-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2"
            aria-hidden="true"
          >
            <circle
              cx="512"
              cy="512"
              r="512"
              fill="url(#8d958450-c69f-4251-94bc-4e091a323369)"
              fill-opacity="0.7"
            />
            <defs>
              <radialGradient
                id="8d958450-c69f-4251-94bc-4e091a323369"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(512 512) rotate(90) scale(512)"
              >
                <stop stop-color="#757BD6" />
                <stop offset="1" stop-color="#E935C1" stop-opacity="0" />
              </radialGradient>
            </defs>
          </svg>
        </div>

        <div className="relative mx-auto max-w-4xl px-4 sm:px-6 lg:px-8">
          <br></br> {alert && <Alert title="Error" text={errText} />} <br></br>
          <label htmlFor="location" className="block pt-8">
            Step 1: Select duration (hours)
          </label>
          <select
            id="duration"
            name="duration"
            value={duration}
            onChange={(e) => changeDuration(e.target.value)}
            className="mt-1 block w-full rounded-lg shadow-md border-gray-300 p-4 pr-10 text-base focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm"
            defaultValue={duration}
          >
            {numberArray.map((i) => (
              <option key={i} value={i}>
                {i}
              </option>
            ))}
          </select>
          <br></br>
          <h3 className="block pt-8"> Step 2: Choose a date and timeslot</h3>
          <div className="flex flex-col justify-between md:flex-row bg-white rounded-lg shadow-lg mt-6">
            <div className="mx-auto max-w-lg md:w-2/5 m-3 overflow-hidden sm:w-1/2 lg:flex lg:max-w-none">
              <div className="flex-1  px-3 py-4 lg:p-3">
                <style>{css}</style>
                <DayPicker
                  mode="single"
                  // modifiersClassNames={{
                  //   selected: "my-selected",
                  //   today: "my-today",
                  // }}
                  // modifiersStyles={{
                  //   disabled: { fontSize: "75%" },
                  // }}
                  fromDate={today}
                  toDate={toDate}
                  selected={selected}
                  onSelect={changeSelected}
                  // locale={zh}
                  footer={footer}
                />
              </div>
            </div>
            <div className="max-w-lg md:w-3/5 overflow-hidden lg:flex lg:max-w-none m-3">
              <div className="flex-1 px-3 py-4 lg:p-3">
                {/* <b>Please choose a time below:</b> */}
                <div className="grid grid-cols-2 p-4 gap-4 sm:grid-cols-3">
                  {!loadingTimeslots &&
                    timeslots.map((timeslot) => (
                      <button
                        type="button"
                        onClick={() => handleTime(timeslot)}
                        className="inline-flex items-center rounded border border-gray-300 bg-white p-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
                      >
                        {(timeslot < 10 ? "0" + timeslot : timeslot) + ":00"}
                      </button>
                    ))}
                </div>{" "}
                {!loadingTimeslots && timeslots.length == 0 && (
                  <center className="h-max">No available time</center>
                )}
                {loadingTimeslots && <LoadingWidget />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <center className="p-12">
        {/* <Link
          to="/cart"
          state={{
            product: product,
            selected: selected,
            time: time,
            duration: duration,
          }}
          className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-16 border border-gray-400 rounded shadow"
        > */}
        {loading && <LoadingWidget />}
        {!loading && (
          <button
            onClick={() => {
              getUserRemainingHours(product);
            }}
            className="bg-[#FCF1ED] text-lg hover:[#C6816A] text-[#9B9188] py-1 px-12 rounded-full"
          >
            Next Step
          </button>
        )}
        {/* </Link> */}
      </center>
    </div>
  );
};

export default DatePicker;
