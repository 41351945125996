import React, { useEffect, useState } from "react";
import { LoadingWidget } from "./LoadingWidget";
import useToken from "../components/useToken";
import StatisticsCard from "./StatisticsCard";
import { Link } from "react-router-dom";
import moment from "moment";
export const AdminSummary = () => {
  const [loading, setLoading] = useState(true);
  const [pendingNumber, setPendingNumber] = useState("");
  const [totalUsers, setTotalUsers] = useState("");
  const [totalTransactions, setTotalTransactions] = useState("");
  const [transactionList, setTransactionsList] = useState([]);
  const [card, setCard] = useState("");
  const [fps, setFps] = useState("");
  const [payme, setPayme] = useState("");
  const { token } = useToken();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://studio-tableau-prd.onrender.com/api/admin/getAllData",
          {
            method: "get",
            headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const string = await response.text();

        const json = string === "" ? {} : JSON.parse(string);
        console.log(json);
        setPendingNumber(json.pendingTransactions);
        setTotalTransactions(json.totalTransactions[0].amount ?? 0);
        setTotalUsers(json.totalUsers);
        setTransactionsList(json.transactions);
        setPayme(json.payme[0].amount ?? 0);
        setCard(json.card[0].amount ?? 0);
        setFps(json.fps[0].amount ?? 0);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
  if (loading) return <LoadingWidget />;
  else
    return (
      <div>
        <div className="mt-4 w-full grid grid-cols-2 md:grid-cols-2 xl:grid-cols-3 gap-4">
          <StatisticsCard
            data={pendingNumber}
            title="Pending for confirmation"
          />
          <StatisticsCard data={totalUsers} title="Total Users" />
          <StatisticsCard
            data={"$" + totalTransactions}
            title="Transaction Total"
          />
          <StatisticsCard data={"$" + card} title="Card Total" />
          <StatisticsCard data={"$" + fps} title="FPS Total" />
          <StatisticsCard data={"$" + payme} title="Payme Total" />
        </div>
        <br />
        <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
          <div className="mb-4 flex items-center justify-between">
            <div>
              <h3 className="text-xl font-bold text-gray-900 mb-2">
                Latest 10 Paid Transactions
              </h3>
              <span className="text-base font-normal text-gray-500">
                This is a list of latest paid transactions
              </span>
            </div>
            <div className="flex-shrink-0">
              <Link
                to="/admin/2"
                className=" font-medium text-cyan-600 hover:bg-gray-100 rounded-lg p-2"
              >
                View all
              </Link>
            </div>
          </div>
          <div className="flex flex-col mt-8">
            <div className="overflow-x-auto rounded-lg">
              <div className="align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Order Number
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Date & Time
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Amount
                        </th>{" "}
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Created At
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {transactionList.map((data) => (
                        <tr
                          key={data.id}
                          className="transition duration-300 ease-in-out hover:bg-gray-100"
                        >
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            {data.booking_sn ?? data.order_number}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-500">
                            {data.payment_method_name}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-semibold text-gray-900">
                            $
                            {data.amount
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td className="p-4 whitespace-nowrap capitalize font-normal text-gray-500">
                            {data.transaction_status}
                          </td>
                          <td className="p-4 whitespace-nowrap  text-gray-900">
                            {moment(data.createdAt).format("YYYY-MM-DD HH:mm")}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    );
};
