import RegisterWidget from "../components/RegisterWidget";
import Layout from "./Layout";
import { motion } from "framer-motion";
export default function Register() {
  return (
    <Layout>
      <motion.div exit={{ opacity: 0 }}>
        <RegisterWidget />
      </motion.div>
    </Layout>
  );
}
