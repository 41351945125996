import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
const IntroWidget = () => {
  return (
    <center>
      <div className="max-w-8xl m-8 bg-gray-100">
        <div className="mx-auto md:flex max-w-md mt-12 py-12 px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <div>
            <LazyLoadImage
              src="./images/MainpageBanner.webp"
              alt="studio-tableau"
              className="object-scale-down pr-4 w-full"
            />
          </div>
          <div className="pl-4">
            <h1 className="text-left font-serif mt-4 text-4xl leading-6 tracking-widest leading-none">
              STUDIO TABLEAU
            </h1>
            <p className="text-left mt-4 text-lg leading-6 text-black">
              Studio Tableau is one of Hong Kong’s foremost music production
              studio that provides the highest standard of recording, rehearsal
              and live-streaming experiences. We are also dedicated to create an
              ideal platform for performing artists and audiences to share and
              be inspired.
            </p>
          </div>
        </div>
      </div>
    </center>
  );
};

export default IntroWidget;
