import React, { useEffect, useState } from "react";
import useToken from "../components/useToken";
import { LoadingWidget } from "./LoadingWidget";

export default function TransactionList() {
  const { token } = useToken();
  const [tranasactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingReceipt, setLoadingReceipt] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [packages, setPackages] = useState([]);
  const [hours, setHours] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setTransactions([]);
      console.log(`Bearer ${token}`);
      const response = await fetch(
        "https://studio-tableau-prd.onrender.com/api/payment/userTransaction",
        {
          method: "post",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const string = await response.text();
      const json = string === "" ? {} : JSON.parse(string);
      console.log(json);
      setPackages(json.allWallet);
      setTransactions(json.paidList);
      setHours(json.remaining_hour);
      setBookings(json.booking_list);
      var tempArr = [];
      for (var i = 0; i < json.paidList.length; i++) {
        tempArr[i] = false;
      }
      setLoadingReceipt(tempArr);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const mapName = (name) => {
    switch (name) {
      case "self_recording":
        return "自助錄音";
      case "practice":
        return "練習";
      case "professional_recording":
        return "專業錄音";
      case "practice,self_recording":
        return "練習+自助錄音";
      case "practice,professional_recording":
        return "練習+專業錄音";
      case "recital":
        return "演奏廳租用";
      default:
        return "";
    }
  };

  // const getInvoice = async (number, index) => {
  //   try {
  //     var tempArr = loadingReceipt;
  //     tempArr[index] = true;
  //     console.log(tempArr);
  //     setLoadingReceipt(tempArr);
  //     const body = JSON.stringify({ orderNumber: number });

  //     const response = await fetch(
  //       "https://studio-tableau-prd.onrender.com/api/payment/invoicePDF",
  //       {
  //         method: "post",
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //         body: body,
  //       }
  //     );
  //     response.blob().then((blob) => {
  //       // Creating new object of PDF file
  //       const fileURL = window.URL.createObjectURL(blob);
  //       // Setting various property values
  //       let alink = document.createElement("a");
  //       alink.href = fileURL;
  //       // alink.download = 'SamplePDF.pdf';
  //       window.open(fileURL);

  //       tempArr[index] = false;
  //       setLoadingReceipt(tempArr);
  //       // alink.click();
  //     });
  //     // const json = string === "" ? {} : JSON.parse(string);
  //     // console.log(json);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  if (loading) return <LoadingWidget />;
  else
    return (
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
        {/* Booking lists */}
        <section aria-labelledby="billing-history-heading">
          <div className="bg-white pt-6 shadow sm:overflow-hidden sm:rounded-md">
            <div className="px-4 sm:px-6">
              <h2
                id="billing-history-heading"
                className="text-lg font-serif font-bold leading-6 text-gray-900"
              >
                Confirmed Bookings
              </h2>
            </div>
            <div className="mt-6 flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden border-t border-gray-200">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-sm font-semibold text-gray-900"
                          >
                            Order Number
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-sm font-semibold text-gray-900"
                          >
                            Type
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-sm font-semibold text-gray-900"
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-sm font-semibold text-gray-900"
                          >
                            Booked Time
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {bookings.length > 0 &&
                          bookings.map((booking) => (
                            <tr key={booking.id} className="hover:bg-gray-100">
                              <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                {booking.booking_sn}
                              </td>
                              <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                {mapName(booking.type)}
                              </td>
                              <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                                <time dateTime={booking.date}>
                                  {booking.date}
                                </time>
                              </td>
                              <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                {booking.start_time.split(":")[0] +
                                  ":" +
                                  booking.start_time.split(":")[1]}
                                -
                                {parseInt(booking.start_time.split(":")[0]) +
                                  booking.duration +
                                  ":00"}
                              </td>
                            </tr>
                          ))}
                        {bookings.length === 0 && (
                          <tr>
                            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                              No result
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Transaction history */}
        <section aria-labelledby="billing-history-heading">
          <div className="bg-white pt-6 shadow sm:overflow-hidden sm:rounded-md">
            <div className="px-4 sm:px-6">
              <h2
                id="billing-history-heading"
                className="text-lg font-serif font-bold  leading-6 text-gray-900"
              >
                Transaction History
              </h2>
            </div>
            <div className="mt-6 flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden border-t border-gray-200">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-sm font-semibold text-gray-900"
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-sm font-semibold text-gray-900"
                          >
                            Order number
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-sm font-semibold text-gray-900"
                          >
                            Amount
                          </th>
                          {/*
                       `relative` is added here due to a weird bug in Safari that causes `sr-only` headings to introduce overflow on the body on mobile.
                     */}
                          <th
                            scope="col"
                            className="relative px-6 py-3 text-left text-sm font-medium text-gray-500"
                          >
                            <span className="sr-only">View receipt</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {tranasactions.length > 0 &&
                          tranasactions.map((payment, i) => (
                            <tr key={payment.id} className="hover:bg-gray-100">
                              <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                                <time dateTime={payment.createdAt}>
                                  {payment.createdAt.substring(0, 10)}
                                </time>
                              </td>
                              <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                {payment.booking_sn ?? payment.order_number}
                              </td>
                              <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                $
                                {payment.amount.toLocaleString(undefined, {
                                  maximumFractionDigits: 2,
                                })}
                              </td>
                              <td className="whitespace-nowrap px-6 py-4 text-right text-sm font-medium">
                                {loadingReceipt[i] && (
                                  <div
                                    className="inline-block h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-warning motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                    role="status"
                                  >
                                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                      Loading...
                                    </span>
                                  </div>
                                )}
                                {!loadingReceipt[i] && (
                                  <a
                                    href={"/invoice/" + payment.order_number}
                                    target="_blank"
                                    className="text-orange-600 hover:text-orange-900"
                                  >
                                    View receipt
                                  </a>
                                )}
                              </td>
                            </tr>
                          ))}
                        {tranasactions.length === 0 && (
                          <tr>
                            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                              No result
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Remaining hours */}
        <section aria-labelledby="billing-history-heading">
          <div className="bg-white pt-6 shadow sm:overflow-hidden sm:rounded-md">
            <div className="px-4 sm:px-6">
              <h2
                id="billing-history-heading"
                className="text-lg font-serif font-bold  leading-6 text-gray-900"
              >
                Remaining Hours
              </h2>
            </div>
            <div className="mt-6 flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden border-t border-gray-200">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-sm font-semibold text-gray-900"
                          >
                            Description
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-sm font-semibold text-gray-900"
                          >
                            Hour
                          </th>
                          {/*
                       `relative` is added here due to a weird bug in Safari that causes `sr-only` headings to introduce overflow on the body on mobile.
                     */}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {Object.entries(hours).map(([k, v]) =>
                          k == "practice,professional_recording" ||
                          k == "practice,self_recording" ||
                          k == "recital" ? (
                            <></>
                          ) : (
                            <tr key={k} className="hover:bg-gray-100">
                              {/* <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                            <time dateTime={payment.createdAt}>
                              {payment.createdAt}
                            </time>
                          </td> */}
                              <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                {mapName(k)}
                              </td>
                              <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                                {v}
                              </td>
                            </tr>
                          )
                        )}
                        {Object.keys(hours).length == 0 && (
                          <tr>
                            <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                              No result
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}
