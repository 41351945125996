import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import useToken from "./useToken";
import { LoadingWidget } from "./LoadingWidget";
import Alert from "./Alert";

export default function AdminCreateBooking() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [alert, setAlert] = useState(false);

  const { token } = useToken();
  //Form data
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  const [user_id, setUserId] = useState("");
  const [durations, setDurations] = useState("");

  const reserveTime = async () => {
    try {
      setSubmitting(true);

      const response = await fetch(
        `https://studio-tableau-prd.onrender.com/api/admin/reserveTime`,
        {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            date: date,
            time: time,
            durations: durations,
            user_id: user_id,
            type: "admin",
          }),
        }
      );
      const string = await response.text();
      const json = string === "" ? {} : JSON.parse(string);
      if (json.result) {
        setSubmitting(false);
        navigate("/admin/3");
      } else {
        setSubmitting(false);
        setAlert(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // fetchData();
  }, []);
  if (loading) return <LoadingWidget />;
  else
    return (
      <div className="relative bg-gray-50">
        <div className="lg:max-w-7xl pl-4 pr-4 mx-auto">
          <br />
          <form className="space-y-8 divide-y divide-gray-200">
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div className="space-y-6 pt-8 sm:space-y-5 sm:pt-10">
                <div>
                  <h2 className="text-base text-lg font-semibold leading-6 text-gray-900">
                    Booking Information
                  </h2>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Create a booking or reserve a time.
                  </p>
                </div>
                {alert && (
                  <Alert title="Error" text="The time is not available." />
                )}

                <div className="space-y-6 sm:space-y-5">
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                    >
                      *Date
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                      <input
                        type="date"
                        name="date"
                        id="date"
                        onChange={(e) => setDate(e.target.value)}
                        autoComplete="date"
                        required
                        className="block p-2 w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="eng_name"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                    >
                      *Time
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                      <input
                        type="number"
                        name="time"
                        id="time"
                        onChange={(e) => setTime(e.target.value)}
                        autoComplete="time"
                        required
                        className="block p-2 w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="price"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                    >
                      *Hours
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                      <input
                        type="number"
                        name="durations"
                        id="durations"
                        onChange={(e) => setDurations(e.target.value)}
                        autoComplete="durations"
                        required
                        className="block p-2 w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                    <label
                      htmlFor="region"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                    >
                      User ID
                    </label>
                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                      <input
                        type="number"
                        name="user_id"
                        id="user_id"
                        onChange={(e) => setUserId(e.target.value)}
                        autoComplete="user_id"
                        className="block p-2 w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-5">
              {!submitting && (
                <div className="flex justify-end gap-x-3">
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Cancel
                  </button>

                  <button
                    type="submit"
                    onClick={reserveTime}
                    className="inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Save
                  </button>
                </div>
              )}
              {submitting && (
                <div className="flex justify-end gap-x-3">
                  <LoadingWidget />
                </div>
              )}
            </div>
            <br />
          </form>
        </div>
      </div>
    );
}
