import React from "react";
import { VerifyEmail } from "../components/VerifyEmail";
import { useLocation } from "react-router-dom";
import Layout from "./Layout";
import { motion } from "framer-motion";
export const EmailVerify = () => {
  const location = useLocation();
  
  return (
    <Layout>
      <motion.div exit={{ opacity: 0 }}>
        <VerifyEmail email={location.state.email} />
      </motion.div>
    </Layout>
  );
};
