import React from "react";
import PackageWidget from "../components/PackageWidget";
import Layout from "./Layout";
import { motion } from "framer-motion";
export const Package = () => {
  return (
    <Layout>
      <motion.div exit={{ opacity: 0 }}>
        <PackageWidget />
      </motion.div>
    </Layout>
  );
};
