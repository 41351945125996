import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { LoadingWidget } from "./LoadingWidget";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
export default function InfoBooking() {
  let { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setInfo({});
      axios
        .get("https://studio-tableau-prd.onrender.com/api/product/" + id, {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data);
          setInfo(res.data);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
    }
  };
  if (loading) return <LoadingWidget />;
  else
    return (
      <div className="relative bg-white">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:items-start lg:gap-24 lg:px-8">
          <div className="relative sm:py-16 lg:py-0">
            {/* <div
              aria-hidden="true"
              className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
            >
              <div className="absolute inset-y-0 right-1/2 w-full rounded-r-3xl bg-gray-50 lg:right-72" />
              <svg
                className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                width={404}
                height={392}
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={392}
                  fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
                />
              </svg>
            </div> */}
            <div className="relative mx-auto max-w-md px-6 sm:max-w-3xl lg:max-w-none lg:px-0 lg:py-20">
              <div className="relative overflow-hidden rounded-2xl pt-64 pb-10 shadow-xl">
                <LazyLoadImage
                  className="absolute inset-0 h-full w-full object-cover"
                  src={info.img}
                />

                <div className="absolute inset-0 bg-transparent mix-blend-multiply" />
                <div className="absolute inset-0 bg-gradient-to-t from-black-600 via-black-600 opacity-90" />
                <div className="relative px-8">
                  <blockquote className="mt-8">
                    <div className="relative text-lg font-medium text-white md:flex-grow"></div>

                    <footer className="mt-4"></footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>

          <div className="relative mx-auto max-w-md px-6 sm:max-w-3xl lg:px-0">
            <div className="pt-12 sm:pt-16 lg:pt-20">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {info.name}
              </h2>{" "}
              <br />
              <Link to="/start_booking" state={{ product: info }}>
                <button className="bg-[#FCF1ED] text-lg hover:[#C6816A] text-[#9B9188] py-1 px-12 rounded-full">
                  Book Now
                </button>
              </Link>
              <div className="mt-6 space-y-6 text-gray-500">
                <p className="text-base leading-7">{info.eng_name}</p>
                <p className="text-lg">
                  ${info.price * info.durations}/ {info.durations}{" "}
                  {info.durations > 1 ? " Hours" : " Hour"}
                </p>
                <p className="text-lg whitespace-pre-line">
                  <font className="font-semibold">Service Descriptions:</font>
                  <br />
                  {info.description}
                </p>
                <p className="text-lg whitespace-pre-line">
                  <font className="font-semibold">Terms and conditions:</font>
                  <br />
                  {info.tnc}
                </p>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
