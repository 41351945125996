import "../App.css";
import React from "react";
import Layout from "./Layout";
import { motion } from "framer-motion";
import IntroWidget from "../components/IntroWidget";
import HomepageGalleryWidget from "../components/HomepageCarousel";
const Homepage = () => {
  return (
    <>
      <Layout>
        <motion.div exit={{ opacity: 0 }}>
          <HomepageGalleryWidget />
          <IntroWidget />
        </motion.div>
      </Layout>
    </>
  );
};

export default Homepage;
