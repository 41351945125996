import SigninWidget from "../components/SigninWidget";
import Layout from "./Layout";
import { motion } from "framer-motion";
export default function Signin() {
  return (
    <Layout>
      <motion.div exit={{ opacity: 0 }}>
        <SigninWidget />
      </motion.div>
    </Layout>
  );
}
