import { ClockIcon, XIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import useToken from "../components/useToken";
import Alert from "./Alert";
import { LoadingWidget } from "./LoadingWidget";
export default function ShoppingCartWidget({ product }) {
  const [items, setItems] = useState([]);
  const { token } = useToken();
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedMethod, setMethod] = useState("");
  const [sum, setSum] = useState(0);
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errText, setText] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    var data = [product.product];

    setItems(data);
    getPaymentMethods();

    setSum(
      product.product.price.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      })
    );
  }, []);

  const onValueChange = (event) => {
    setMethod(event.target.value);
  };

  const checkout = async () => {
    console.log(selectedMethod);
    console.log(token);
    if (!selectedMethod) {
      setAlert(true);
      setText("You must select your payment method.");
    } else if (selectedMethod == 1) {
      try {
        setAlert(false);
        setLoading(true);
        let body = JSON.stringify({
          item_type: "package",
          item_id: items[0].id,
          payment_method_id: 1,
          // quantity: 1,
        });

        const response = await fetch(
          "https://studio-tableau-prd.onrender.com/api/payment/checkout",
          {
            method: "post",
            headers: {
              Accept: "application/json, text/plain, */*",
              Authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
            body: body,
          }
        );
        const string = await response.text();
        console.log(string);
        const json = string === "" ? {} : JSON.parse(string);
        console.log(json);
        setLoading(false);
        window.location.replace(json.paymentLink);
      } catch (error) {
        console.log(error);
      }
    } else if (selectedMethod == 2) {
      setAlert(false);
      setLoading(true);
      let body = JSON.stringify({
        item_type: "package",
        item_id: items[0].id,
        payment_method_id: 2,
      });

      const response = await fetch(
        "https://studio-tableau-prd.onrender.com/api/payment/checkout",
        {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: body,
        }
      );
      const string = await response.text();
      const json = string === "" ? {} : JSON.parse(string);
      setLoading(false);
      navigate("/payme", {
        state: { order_number: json.order_number, price: json.price },
        replace: true,
      });
    } else if (selectedMethod == 3) {
      setAlert(false);
      setLoading(true);
      let body = JSON.stringify({
        item_type: "package",
        item_id: items[0].id,
        payment_method_id: 3,
      });

      const response = await fetch(
        "https://studio-tableau-prd.onrender.com/api/payment/checkout",
        {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: body,
        }
      );
      const string = await response.text();
      const json = string === "" ? {} : JSON.parse(string);
      setLoading(false);
      navigate("/fps", {
        state: { order_number: json.order_number, price: json.price },
        replace: true,
      });
    }
  };

  const getPaymentMethods = async () => {
    try {
      // console.log(body);
      const response = await fetch(
        "https://studio-tableau-prd.onrender.com/api/payment/methods",
        {
          method: "get",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
        }
      );
      const string = await response.text();

      const json = string === "" ? {} : JSON.parse(string);
      setPaymentMethods(json);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 pt-16 pb-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <h1 className="text-3xl font-bold tracking-tight pb-8 text-gray-900 sm:text-4xl">
          Summary
        </h1>
        {/* <form className="mt-12 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16"> */}
        <section aria-labelledby="cart-heading" className="lg:col-span-7">
          <h2 id="cart-heading" className="sr-only">
            Items in your shopping cart
          </h2>
          <ul
            role="list"
            className="divide-y divide-gray-200 border-t border-b border-gray-200"
          >
            {items.map((data, productIdx) => (
              <li key={product.id} className="flex py-6 sm:py-10">
                {/* <div className="flex-shrink-0">
                <img
                  src={data.product.img}
                  alt={data.product.img}
                  className="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48"
                />
              </div> */}

                <div className="ml-4 flex flex-1 flex-col justify-between sm:ml-6">
                  <div className="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                    <div>
                      <div className="flex justify-between">
                        <h3 className="text-lg">{data.name}</h3>
                      </div>
                    </div>

                    <div className="mt-4 sm:mt-0 sm:pr-9">
                      <div className="absolute top-0 right-0">
                        <button
                          type="button"
                          className="-m-2 inline-flex p-2 text-gray-400 hover:text-gray-500"
                        >
                          <span className="sr-only">Remove</span>
                          <XIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <p className="text-sm font-medium text-gray-900">
                    <p className="text-gray-500">{data.eng_name}</p>
                  </p>
                  <p className="mt-4 flex space-x-2 text-sm text-gray-700">
                    <ClockIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-300"
                      aria-hidden="true"
                    />

                    <span> {data.durations} Hours</span>
                  </p>
                </div>
              </li>
            ))}
          </ul>
          <br />
          <div className=" w-full max-w-screen-lg">
            <div className="lg:col-span-2">
              <h2 className="text-md font-bold ">Choose your payment Method</h2>{" "}
              <div className="bg-white rounded mt-4 shadow-sm">
                {" "}
                {alert && <Alert title="Error" text={errText} />}
                {paymentMethods.map((i) => (
                  <div className="flex items-center px-8 py-5" key={i.id}>
                    {/* <input
                        className="appearance-none w-4 h-4 rounded-full border-2 border-white ring-2 ring-blue-600 ring-opacity-100"
                        type="radio"
                        // checked={selectedMethod === i.id}
                        // value={i.id}
                        // id={i.name}
                        // onChange={onValueChange}
                      /> */}
                    <input
                      className=" w-4 h-4 rounded-full border-2 border-white  ring-opacity-100"
                      type="radio"
                      checked={selectedMethod == i.id}
                      value={i.id}
                      id={i.name}
                      onChange={onValueChange}
                    />
                    {/* <label className="text-sm font-medium ml-4">
                        Credit Card
                      </label> */}
                    <img
                      onClick={() => setMethod(i.id)}
                      src={i.image}
                      className="pl-8 h-10"
                    />
                    {/* <label className="text-sm font-medium ml-4"> {i.name}</label> */}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Order summary */}
        <section
          aria-labelledby="summary-heading"
          className="mt-16 rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8"
        >
          <h2
            id="summary-heading"
            className="text-lg font-medium text-gray-900"
          >
            Order summary
          </h2>

          <dl className="mt-6 space-y-4">
            <div className="flex items-center justify-between">
              <dt className="text-sm text-gray-600">Subtotal</dt>
              <dd className="text-sm font-medium text-gray-900">${sum}</dd>
            </div>
            {/* <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                <dt className="flex items-center text-sm text-gray-600">
                  <span>Shipping estimate</span>
                  <a
                    href="#"
                    className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">
                      Learn more about how shipping is calculated
                    </span>
                    <QuestionMarkCircleIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </a>
                </dt>
                <dd className="text-sm font-medium text-gray-900">$5.00</dd>
              </div>
              <div className="flex items-center justify-between border-t border-gray-200 pt-4">
                <dt className="flex text-sm text-gray-600">
                  <span>Tax estimate</span>
                  <a
                    href="#"
                    className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">
                      Learn more about how tax is calculated
                    </span>
                    <QuestionMarkCircleIcon
                      className="h-5 w-5"
                      aria-hidden="true"
                    />
                  </a>
                </dt>
                <dd className="text-sm font-medium text-gray-900">$8.32</dd>
              </div> */}
            <div className="flex items-center justify-between border-t border-gray-200 pt-4">
              <dt className="text-base font-medium text-gray-900">
                Order total
              </dt>
              <dd className="text-base font-medium text-gray-900">${sum}</dd>
            </div>
          </dl>

          <div className="mt-6">
            {loading && <LoadingWidget />}
            {!loading && (
              <button
                // type="submit"
                onClick={checkout}
                className="w-full rounded-md border border-transparent bg-yellow-600 py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 focus:ring-offset-gray-50"
              >
                Checkout
              </button>
            )}
          </div>
        </section>
        {/* </form> */}
      </div>
    </div>
  );
}
