import React from "react";
import ConfirmWidget from "../components/ConfirmWidget";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import Layout from "./Layout";
const ConfirmPage = () => {
  const location = useLocation();
  return (
    <Layout>
      <motion.div exit={{ opacity: 0 }}>
        <ConfirmWidget
          product={location.state}
          time={location.time}
          selected={location.selected}
          duration={location.duration}
          booking_sn={location.booking_sn}
          order_number={location.order_number}
        />
      </motion.div>
    </Layout>
  );
};

export default ConfirmPage;
