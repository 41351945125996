import React from "react";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import Layout from "./Layout";
import ConfirmWidgetNoWallet from "../components/ConfirmWidgetNoWallet";

const ConfirmPaymentOnlyPage = () => {
  const location = useLocation();
  return (
    <Layout>
      <motion.div exit={{ opacity: 0 }}>
        <ConfirmWidgetNoWallet
          product={location.state}
          time={location.time}
          selected={location.selected}
          duration={location.duration}
          booking_sn={location.booking_sn}
          order_number={location.order_number}
        />
      </motion.div>
    </Layout>
  );
};

export default ConfirmPaymentOnlyPage;
