import React, { useEffect, useState } from "react";
import { Document, Page } from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
import useToken from "../components/useToken";
import moment from "moment";
import { LoadingWidget } from "../components/LoadingWidget";
import "./invoice.scoped.css";
export const Invoice = () => {
  let { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [transaction, setTransaction] = useState({});
  const [productData, setProductData] = useState({});
  const [bookings, setBookings] = useState({});
  const { token } = useToken();
  const fetchData = async () => {
    try {
      setLoading(true);
      setUserData({});
      setTransaction({});
      setProductData({});
      setBookings([]);
      const response = await fetch(
        "https://studio-tableau-prd.onrender.com/api/payment/invoiceInfo",
        {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ orderNumber: id }),
        }
      );
      console.log(response);
      const string = await response.text();
      const json = string === "" ? {} : JSON.parse(string);

      setUserData(json.userData);
      setTransaction(json.transaction);
      setProductData(json.product_data);
      setBookings(json.bookings);
      setLoading(false);
    } catch (error) {
      console.log(error);
      await fetchData();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  if (loading) return <LoadingWidget />;
  else
    return (
      <Document>
        <Page size="A4">
          <div className="html1">
            <div className="div1">
              <header className="bg-white px-8 pt-8">
                <div class="flex flex-row justify-end content-end">
                  <div className="flex flex-col">
                    <img
                      className="ml-auto object-scale-down float-right text-right w-1/2"
                      src="/images/Logo.webp"
                    />
                    <div className="py-4 text-right pr-16 text-sm">
                      {" "}
                      Studio Tableau
                    </div>
                  </div>

                  <div className="text-right text-xs ml-16 whitespace-nowrap">
                    <br />
                    <p>Studio Tableau</p>
                    <br />
                    <p className="">Room 2303, 23/F, Ricky Center,</p>
                    <p>
                      36 Chong Yip Street, Kwun Tong,
                      <br />
                      Kowloon, Hong Kong
                    </p>{" "}
                    <br />
                    <p>+852 5119 6273</p> <br />
                    <p> contact@studiotableau.org</p>
                    {/* <p>Opening hours: 9:00-23:00</p> */}
                  </div>
                </div>
              </header>{" "}
              <div className=" px-16 pt-8 text-gray-600">
                <p className="text-xl tracking-widest">RECEIPT</p>
                {!transaction.booking_sn && <>#{transaction.order_number}</>}
                {transaction.booking_sn && <>#{transaction.booking_sn}</>}{" "}
                <br />
                Issued Date:{" "}
                {moment(transaction.createdAt).format("DD MMM YYYY")}
                <br /> <br />
                Name: {userData.last_name + " " + userData.first_name}
                <br />
                Phone number: {userData.phone_number}
                <br />
                Email: {userData.email}
                <br /> <br />
                <table class="border-none w-full table-auto">
                  <thead className="border-none">
                    <tr className="text-left">
                      <th className="bg-[#B6A370] border-none text-white font-light p-2">
                        <span>DESCRIPTION</span>
                      </th>
                      <th className="bg-[#B6A370] border-none text-white font-light p-2">
                        <span>QTY</span>
                      </th>
                      <th className="bg-[#B6A370] border-none text-white font-light p-2">
                        <span>PRICE</span>
                      </th>
                      <th className="bg-[#B6A370] border-none text-white text-right font-light p-2">
                        <span>TOTAL</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody c>
                    <tr>
                      <td className="td1">
                        <span className="text-gray-600">
                          {!transaction.booking_sn && (
                            <>
                              {productData.eng_name +
                                " (" +
                                productData.durations}
                              {productData.durations > 1 ? " Hours)" : " Hour)"}
                            </>
                          )}
                          {transaction.booking_sn && (
                            <> {productData.eng_name}</>
                          )}{" "}
                        </span>
                        {bookings && (
                          <p className="text-gray-500 text-xs">
                            {moment(bookings[0].date).format("DD MMM YYYY") +
                              " "}
                            {bookings[0].start_time.split(":")[0] +
                              ":" +
                              bookings[0].start_time.split(":")[1]}
                            -
                            {parseInt(bookings[0].start_time.split(":")[0]) +
                              bookings[0].duration +
                              ":00"}
                          </p>
                        )}
                      </td>
                      <td className="td1">
                        <span>
                          {!transaction.booking_sn && 1}
                          {transaction.booking_sn && bookings[0].duration}
                        </span>
                      </td>{" "}
                      <td className="td1">
                        <span data-prefix>$</span>
                        <span>
                          {productData.price.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      </td>
                      <td className="pr-2 text-right">
                        <span data-prefix>$</span>
                        <span>
                          {transaction.amount.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr class="h-0.5 mx-auto bg-[#B6A370] border-0 rounded mt-20 dark:bg-[#B6A370]"></hr>
                <table class="table1 balance text-gray-600">
                  <tr className="border-b-[2px] border-b border-[#B6A370] ">
                    <td>
                      <span className="pl-4">Total</span>
                    </td>
                    <td className="td1">
                      <span data-prefix>$</span>
                      <span>
                        {transaction.amount.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </td>
                  </tr>
                  {/* <tr>
                    <th className="th1">
                      <span>Paid By</span>
                    </th>
                    <td className="td1">
                      <span>{transaction.payment_method_name}</span>
                    </td>
                  </tr> */}
                  {/* <tr>
                    <th className="th1">
                      <span>Amount Paid</span>
                    </th>
                    <td className="td1">
                      <span data-prefix>$</span>
                      <span>
                        {transaction.amount.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </td>
                  </tr> */}
                </table>{" "}
                <br />
                <br />
                <br />
                <br /> <br /> <br />
                <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
                <br />{" "}
                <div class=" p-6 text-center">
                  Thank you for your visit!
                  <br />
                  <span className="text-[9px]">
                    © 2023 Studio Tableau | Room 2303, 23/F, Ricky Centre, 36
                    Chong Yip St, Kwun Tong, Hong Kong
                  </span>
                </div>
                <div></div>{" "}
              </div>{" "}
            </div>
          </div>
        </Page>
      </Document>
    );
};
