import "../App.css";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import DatePicker from "../components/DatePicker.js";
import useToken from "../components/useToken";
import Layout from "./Layout";
import { motion } from "framer-motion";
function StartBooking(state) {
  const { token } = useToken();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    console.log(state);
    console.log(token);
    console.log("product props is", location.state.product);
    if (!token) {
      navigate("/signin");
    }
  });

  if (token) {
    return (
      <Layout>
        <motion.div exit={{ opacity: 0 }}>
          <DatePicker product={location.state.product} />
          {/* <div></div> */}
        </motion.div>
      </Layout>
    );
  } else {
    return <div></div>;
  }
}

export default StartBooking;
