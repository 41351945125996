import "../App.css";
import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ShoppingCartWidget from "../components/ShoppingCartWidget.js";
import useToken from "../components/useToken";
import Layout from "./Layout";
import { motion } from "framer-motion";

function ShoppingCart() {
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useToken();

  useEffect(() => {
    console.log(token);
    console.log("product props is", location.state.product);
    if (!token) {
      navigate("/signin");
    }
  });

  if (token) {
    return (
      <Layout>
        <motion.div exit={{ opacity: 0 }}>
          <ShoppingCartWidget
            product={location.state}
            time={location.time}
            timeslots={location.timeslots}
          />
        </motion.div>
      </Layout>
    );
  } else {
    return <div></div>;
  }
}

export default ShoppingCart;
