import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import ScrollToTop from "./components/ScrollToTop";
import AnimatedRoutes from "./AnimatedRoutes";
import "./fonts/Eina01-Bold.ttf";
import "./fonts/Eina01-BoldItalic.ttf";
import "./fonts/Eina01-SemiboldItalic.ttf";
import "./fonts/Eina01-SemiBold.ttf";
import "./fonts/Eina01-RegularItalic.ttf";
import "./fonts/Eina01-Regular.ttf";
import "./fonts/Eina01-Light.ttf";

export default function App() {
  return (
    <BrowserRouter basename="/" hashType="noslash">
      <ScrollToTop>
        <AnimatedRoutes />
      </ScrollToTop>
    </BrowserRouter>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
