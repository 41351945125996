import React, { useEffect, useState, Fragment } from "react";
import useToken from "../components/useToken";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import { LoadingWidget } from "./LoadingWidget";
import Alert from "./Alert";

export default function ConfirmWidgetNoWallet({ product }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedMethod, setMethod] = useState("");
  const [items, setItems] = useState([]);
  const [chosenTime, setChosenTime] = useState("");
  const [chosenDate, setChosenDate] = useState("");
  const [duration, setDuration] = useState();
  const [open, setOpen] = useState(false);
  const { token } = useToken();

  const [alert, setAlert] = useState(false);
  const [errText, setText] = useState();

  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  useEffect(() => {
    getPaymentMethods();
    setDuration(parseInt(product.duration));
    setChosenTime(product.time);
    setChosenDate(convert(product.selected));
    setItems([product.product]);
  }, []);

  const getPaymentMethods = async () => {
    try {
      // console.log(body);
      const response = await fetch(
        "https://studio-tableau-prd.onrender.com/api/payment/methods",
        {
          method: "get",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
        }
      );
      const string = await response.text();

      const json = string === "" ? {} : JSON.parse(string);
      setPaymentMethods(json);
    } catch (error) {
      console.log(error);
    }
  };

  const makeBooking = async () => {
    try {
      if (token) {
        console.log(selectedMethod);
        if (!selectedMethod) {
          setAlert(true);
          setText("You must select your payment method.");
        } else if (selectedMethod == 1) {
          setLoading(true);
          setAlert(false);
          let body = JSON.stringify({
            item_type: "product",
            date: convert(product.selected),
            time: product.time,
            item_id: product.product.id,
            payment_method_id: 1,
            durations: duration,
            order_number: product.order_number,
            booking_sn: product.booking_sn,
            // quantity: 1,
          });

          const response = await fetch(
            "https://studio-tableau-prd.onrender.com/api/payment/checkout",
            {
              method: "post",
              headers: {
                Accept: "application/json, text/plain, */*",
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
              },
              body: body,
            }
          );
          const string = await response.text();
          const json = string === "" ? {} : JSON.parse(string);
          window.location.replace(json.paymentLink);
          setLoading(false);
        } else {
          setLoading(true);
          setAlert(false);
          let body = JSON.stringify({
            item_type: "product",
            date: convert(product.selected),
            time: product.time,
            item_id: product.product.id,
            payment_method_id: selectedMethod,
            durations: duration,
            order_number: product.order_number,
            booking_sn: product.booking_sn,
            // quantity: 1,
          });

          const response = await fetch(
            "https://studio-tableau-prd.onrender.com/api/payment/checkout",
            {
              method: "post",
              headers: {
                Accept: "application/json, text/plain, */*",
                Authorization: "Bearer " + token,
                "Content-Type": "application/json",
              },
              body: body,
            }
          );
          const string = await response.text();
          const json = string === "" ? {} : JSON.parse(string);
          window.location.replace(json.paymentLink);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      makeBooking();
    }
  };

  return (
    <div>
      <div className="bg-gray-50">
        <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:px-0">
          <h1 className="text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Booking Summary
          </h1>
          <br />
          <section aria-labelledby="cart-heading">
            <h2 id="cart-heading" className="sr-only">
              You have chosen
            </h2>

            <ul
              role="list"
              className="divide-y divide-gray-200 border-t border-b border-gray-200"
            >
              {items.map((product) => (
                <li key={product.id} className="flex py-6">
                  <div className="flex-shrink-0">
                    <img
                      src={product.img}
                      alt={product.img}
                      className="h-24 w-24 rounded-md object-cover object-center sm:h-32 sm:w-32"
                    />
                  </div>

                  <div className="ml-4 flex flex-1 flex-col sm:ml-6">
                    <div>
                      <div className="flex justify-between">
                        <h4 className="text-sm">
                          {/* <a
                            href={product.href}
                            className="font-medium text-gray-700 hover:text-gray-800"
                          > */}
                          {product.name}
                          <br />
                          {chosenDate}
                          {/* </a> */}
                        </h4>
                        {/* <p className="ml-4 text-sm font-medium text-gray-900">
                          ${product.price}
                        </p> */}

                        <span>
                          {(chosenTime < 10 ? "0" + chosenTime : chosenTime) +
                            ":00"}
                          -
                          {(chosenTime + duration < 10
                            ? "0" + chosenTime + duration
                            : chosenTime + duration) + ":00"}
                        </span>
                      </div>
                    </div>

                    {/* <div className="mt-4 flex flex-1 items-end justify-between">
                      <div className="ml-4">
                        <button
                          type="button"
                          className="text-sm font-medium text-yellow-600 hover:text-yellow-500"
                        >
                          <span>Remove</span>
                        </button>
                      </div>
                    </div> */}
                  </div>
                </li>
              ))}
            </ul>
          </section>
          <br />
          <h5 className="text-lg font-bold"> Pay this booking by</h5>
          <br />
          {paymentMethods.map((i) => (
            <div className="flex items-center px-8 py-5" key={i.id}>
              {/* <input
                        className="appearance-none w-4 h-4 rounded-full border-2 border-white ring-2 ring-blue-600 ring-opacity-100"
                        type="radio"
                        // checked={selectedMethod === i.id}
                        // value={i.id}
                        // id={i.name}
                        // onChange={onValueChange}
                      /> */}
              <input
                className=" w-4 h-4 rounded-full border-2 border-white  ring-opacity-100"
                type="radio"
                checked={selectedMethod == i.id}
                value={i.id}
                id={i.name}
                onChange={(e) => setMethod(e.target.value)}
              />
              {/* <label className="text-sm font-medium ml-4">
                        Credit Card
                      </label> */}
              <img
                onClick={() => setMethod(i.id)}
                src={i.image}
                className="pl-8 h-10"
              />
              {/* <label className="text-sm font-medium ml-4"> {i.name}</label> */}
            </div>
          ))}
          {/* Order summary */}
          <section aria-labelledby="summary-heading" className="mt-10">
            <div className="mt-10">
              {alert && <Alert title="Error" text={errText} />}

              {loading && <LoadingWidget />}
              {!loading && (
                <button
                  onClick={() => {
                    makeBooking();
                  }}
                  className="w-full bg-[#FCF1ED] text-lg hover:[#C6816A] text-[#9B9188] py-1 px-12 rounded-full shadow-sm h-12"
                >
                  Confirm Booking
                </button>
              )}
            </div>

            <div className="mt-6 text-center text-sm">
              <p>
                or{" "}
                <button
                  href="#"
                  className="font-medium text-amber-600 hover:text-yellow-500"
                  onClick={() => navigate("/account", {})}
                >
                  Cancel
                  <span aria-hidden="true"> &rarr;</span>
                </button>
              </p>
            </div>
          </section>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => navigate("/account")}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <CheckIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Booking successful
                      </Dialog.Title>
                      {/* <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Consequatur amet labore.
                        </p>
                      </div> */}
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-yellow-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
                      onClick={() => navigate("/account", { replace: true })}
                    >
                      Go to my transaction page
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
