import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import AddressWidget from "../components/IntroWidget";
import GalleryWidget from "../components/GalleryWidget";
import Layout from "./Layout";
import { motion } from "framer-motion";
const Merchant = () => {
  return (
    <Layout>
      <motion.div exit={{ opacity: 0 }}>
        <div className="items-center">
          <GalleryWidget />
        </div>
      </motion.div>
    </Layout>
  );
};

export default Merchant;
