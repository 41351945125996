import React from "react";
import { SuccessWidget } from "../components/SuccessWidget";
import Layout from "./Layout";
import { motion } from "framer-motion";
export const PaymentSuccess = () => {
  return (
    <Layout>
      <motion.div exit={{ opacity: 0 }}>
        <SuccessWidget />
      </motion.div>
    </Layout>
  );
};
