import React from "react";
import Layout from "./Layout";
import { motion } from "framer-motion";
import InfoBooking from "../components/InfoBooking";
export const BookingInfoPage = () => {
  return (
    <Layout>
      <motion.div exit={{ opacity: 0 }}>
        <InfoBooking />
      </motion.div>
    </Layout>
  );
};
