import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AdminSummary } from "./AdminSummary";
import { AdminTransaction } from "./AdminTransaction";
import { AdminUser } from "./AdminUsers";
import { AdminBooking } from "./AdminBookings";
import { AdminContentList } from "./AdminContentList";
import { useNavigate } from "react-router-dom";

const tabs = [
  { name: "Summary", href: "/admin/1", index: 1, current: false },
  { name: "Transactions", href: "/admin/2", index: 2, current: false },
  { name: "Bookings", href: "/admin/3", index: 3, current: false },
  { name: "Users", href: "/admin/4", index: 4, current: false },
  { name: "Contents", href: "/admin/5", index: 5, current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const AdminWidget = () => {
  let { id } = useParams();
  const [tabIndex, setTabIndex] = useState(1);
  const navigate = useNavigate();
  useEffect(() => {
    setTabIndex(id);
  }, []);

  const changeTab = (id) => {
    navigate("/admin/" + id);
  };

  return (
    <div className="relative bg-gray-50">
      <div className="lg:max-w-7xl mx-auto">
        <br />
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            value={tabIndex}
            onChange={(e) => changeTab(e.target.value)}
            className="block w-full rounded-md border-gray-300 py-2 pl-3 p-10 text-base focus:border-yellow-500 focus:outline-none focus:ring-yellow-500 sm:text-sm"
            // defaultValue={tabs.find((tab) => tab.index == tabIndex).name}
          >
            {tabs.map((tab) => (
              <option
                onClick={() => changeTab(tab.index)}
                id={tab.index}
                key={tab.index}
                value={tab.index}
              >
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  onClick={() => setTabIndex(tab.index)}
                  className={classNames(
                    tab.index == tabIndex
                      ? "border-yellow-500 text-yellow-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium"
                  )}
                  aria-current={tab.index == tabIndex ? "page" : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
        <br />
        {tabIndex == 1 && <AdminSummary />}
        {tabIndex == 2 && <AdminTransaction />}
        {tabIndex == 3 && <AdminBooking />}
        {tabIndex == 4 && <AdminUser />}
        {tabIndex == 5 && <AdminContentList />}
      </div>
    </div>
  );
};
