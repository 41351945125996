import React, { useEffect } from "react";
import { PaymeWidget } from "../components/PaymeWidget";
import Layout from "./Layout";
import { useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";
import useToken from "../components/useToken";

export const PaymeBooking = () => {
  const [searchParams] = useSearchParams();
  const { token } = useToken();

  useEffect(() => {
    confirmSuccess();
  }, []);

  const confirmSuccess = async () => {
    try {
      fetch("https://studio-tableau-prd.onrender.com/api/payment/success", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          iv: searchParams.get("iv"),
          content: searchParams.get("content"),
          session_id: searchParams.get("session_id") ?? "",
        }),
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <motion.div exit={{ opacity: 0 }}>
        <PaymeWidget
          order_number={searchParams.get("orderNumber")}
          price={searchParams.get("price")}
        />
      </motion.div>
    </Layout>
  );
};
