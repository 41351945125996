import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import useToken from "../components/useToken";
export const ConfirmEmail = () => {
  const navigate = useNavigate();
  const { setToken } = useToken();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    verifyOTP();
  }, []);

  const verifyOTP = async () => {
    const response = await fetch(
      "https://studio-tableau-prd.onrender.com/api/auth/verifyOTP",
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          iv: searchParams.get("iv"),
          content: searchParams.get("content"),
        }),
      }
    );
    const string = await response.text();
    const json = string === "" ? {} : JSON.parse(string);
    if (json.result) {
      setToken(json.token);
      setTimeout(() => navigate("/"), 5000);
    }
  };

  return (
    <div className="bg-gray-50">
      <div className="flex items-center justify-center h-96 min-w-screen">
        <div className="max-w-xl p-8 text-center text-gray-800 bg-white shadow-sm lg:max-w-3xl rounded-3xl lg:p-12">
          <h3 className="text-2xl">
            Congratulations! Your account has been activated.
          </h3>
          <div className="flex justify-center p-8">
            <svg
              viewBox="0 0 24 24"
              className="text-green-600 w-16 h-16 mx-auto my-6"
            >
              <path
                fill="currentColor"
                d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
              ></path>
            </svg>
          </div>

          <p>You will be redirected to homepage in 5 seconds.</p>
        </div>
      </div>
    </div>
  );
};
