import React, { useEffect, useState } from "react";
import { LoadingWidget } from "./LoadingWidget";
import useToken from "../components/useToken";
import { useNavigate } from "react-router-dom";
import moment from "moment";
export const AdminUser = () => {
  const [loading, setLoading] = useState(true);
  const [totalItems, setTotalItems] = useState();
  const [userList, setUserList] = useState([]);
  const [searchBy, setSearchBy] = useState("id");
  const [search, setSearch] = useState();
  const { token } = useToken();
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (page) => {
    try {
      const response = await fetch(
        "https://studio-tableau-prd.onrender.com/api/admin/getAllUserData",
        {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ page: page ?? 0 }),
        }
      );
      const string = await response.text();
      const json = string === "" ? {} : JSON.parse(string);

      setTotalItems(json.totalItems);
      setUserList(json.rows);
      setCurrentPage(json.currentPage);
      setTotalPage(json.totalPages);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const previousPage = async () => {
    try {
      if (currentPage != 0) {
        setCurrentPage(currentPage - 1);
        fetchData(currentPage - 1);
        window.scrollTo(0, 150);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const nextPage = async () => {
    try {
      if (currentPage < totalPage) {
        setCurrentPage(currentPage + 1);
        fetchData(currentPage + 1);
        window.scrollTo(0, 150);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const searchUserID = async (event) => {
    try {
      event.preventDefault();
      console.log(search);
      const body = JSON.stringify({
        field: searchBy,
        data: search,
      });
      console.log(body);
      const response = await fetch(
        "https://studio-tableau-prd.onrender.com/api/users",
        {
          method: "post",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: body,
        }
      );

      const string = await response.text();
      const json = string === "" ? {} : JSON.parse(string);
      console.log(json);
      setUserList(json);
    } catch (error) {
      console.log(error);
    }
  };

  // const exportUser = async (event) => {
  //   try {
  //     event.preventDefault();

  //     const response = await fetch(
  //       "https://studio-tableau-prd.onrender.com/api/admin/exportBookingList",
  //       {
  //         method: "get",
  //         headers: {
  //           Accept: "application/json, text/plain, */*",
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const activateAccount = async (id) => {
    try {
      console.log(id);
      const response = await fetch(
        `https://studio-tableau-prd.onrender.com/api/users/${id}`,
        {
          method: "put",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const string = await response.text();
      const json = string === "" ? {} : JSON.parse(string);
      console.log(json);
      window.location.reload(false);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAccount = async (id) => {
    try {
      console.log(id);
      let body = JSON.stringify({ id: id });
      console.log(body);
      await fetch(`https://studio-tableau-prd.onrender.com/api/users/${id}`, {
        method: "delete",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: body,
      });
      setUserList(userList.filter((user) => user.id !== id));
    } catch (error) {
      console.log(error);
    }
  };

  if (loading) return <LoadingWidget />;
  else
    return (
      <div>
        <br />
        <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
          <div className="mb-4 flex items-center justify-between">
            <div>
              <h3 className="text-xl font-bold text-gray-900 mb-2">
                All Users ({totalItems})
              </h3>
              <span className="text-base font-normal text-gray-500">
                This is a list of all users
              </span>
            </div>

            <div className="flex-shrink-0">
              <div className="pt-2 relative mx-auto text-gray-600">
                <a
                  href="https://studio-tableau-prd.onrender.com/api/admin/exportMemberList"
                  className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
                >
                  Export
                </a>
              </div>
            </div>
          </div>
          <form onSubmit={searchUserID}>
            <div className="relative mt-2 rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 flex items-center">
                <label htmlFor="field" className="sr-only">
                  Field
                </label>
                <select
                  id="field"
                  name="field"
                  value={searchBy}
                  autoComplete="field"
                  onChange={(e) => setSearchBy(e.target.value)}
                  className="h-full rounded-md border-0 bg-transparent py-0 pl-3 pr-7 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:"
                >
                  <option value="id">ID</option>
                  <option value="phone">Phone</option>
                  <option value="email">Email</option>
                </select>
              </div>
              <input
                className="block w-full rounded-md border-0 py-1.5 pl-32 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm: sm:leading-6"
                type="search"
                name="search"
                placeholder="Search"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </form>
          <div className="flex flex-col mt-8">
            <div className="overflow-x-auto rounded-lg">
              <div className="align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          ID
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Phone
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Created At
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {userList.map((data) => (
                        <tr
                          onClick={() => navigate(`/user_details/${data.id}`)}
                          key={data.id}
                          className="transition duration-300 ease-in-out hover:bg-gray-100"
                        >
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            {data.id}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-500">
                            {data.email}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            {data.last_name} {data.first_name}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            {data.phone_number}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            {data.status == 0 ? "Unverified" : "Activated"}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-normal text-gray-900">
                            {moment(data.createdAt).format("YYYY-MM-DD HH:mm")}
                          </td>
                          <td className="p-4 whitespace-nowrap  font-semibold text-gray-900">
                            {data.status == 0 && (
                              <button
                                onClick={() => activateAccount(data.id)}
                                className="bg-transparent hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded"
                              >
                                Activate
                              </button>
                            )}
                            <button
                              onClick={() =>
                                navigate(`/user_details/${data.id}`)
                              }
                              className="bg-transparent ml-2 hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                            >
                              View
                            </button>
                            <button
                              onClick={() => deleteAccount(data.id)}
                              className="bg-transparent ml-2 hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded"
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="flex">
            {currentPage != 0 && currentPage < totalPage && (
              <button
                onClick={previousPage}
                className="inline-flex items-center px-4 py-2 ml-3  font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700"
              >
                Back
              </button>
            )}
            {currentPage + 1 < totalPage && (
              <>
                <div className="grow h-14 ..."></div>
                <button
                  onClick={nextPage}
                  className="inline-flex items-center px-4 py-2 ml-3  font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700"
                >
                  Next
                </button>
              </>
            )}
          </div>
        </div>
        <br />
      </div>
    );
};
