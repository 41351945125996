import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useToken from "../components/useToken";
import Alert from "./Alert";
export const ResetWidget = () => {
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [alert, setAlert] = useState(false);
  const [errText, setText] = useState();
  const navigate = useNavigate();
  const { token } = useToken();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!password || !confirmpassword) {
      setAlert(true);
      setText("Please input all fields.");
    } else if (password != confirmpassword) {
      setAlert(true);
      setText("Password does not match.");
      setPassword("");
      setConfirmPassword("");
    } else {
      try {
        console.log("called");
        let body = JSON.stringify({
          password: password,
        });
        const response = await fetch(
          "https://studio-tableau-prd.onrender.com/api/auth/resetPassword",
          {
            method: "post",
            headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: body,
          }
        );
        const string = await response.text();

        const json = string === "" ? {} : JSON.parse(string);
        console.log(json);
        if (json.result) {
          navigate("/account");
        } else {
          setPassword("");
          setConfirmPassword("");
          setAlert(true);
          setText(json.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
      <div className=" my-10 bg-white p-8 rounded-xl shadow shadow-slate-300">
        <h1 className="text-4xl font-medium">Reset password</h1>
        {alert && (
          <div>
            <br />
            <Alert title="Error" text={errText} />
          </div>
        )}
        <div className="my-10">
          <div className="flex flex-col space-y-5">
            {/* <label for="email">
              <p className="font-medium text-slate-700 pb-2 pl-2">
                Current Password
              </p>
              <input
                id="old_password"
                name="password"
                type="password"
                className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow"
                placeholder="Enter your current password"
              />
            </label> */}
            <label for="password">
              <p className="font-medium text-slate-700 pb-2 pl-2">
                New Password
              </p>
              <input
                id="old_password"
                name="password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow"
                placeholder="Enter your current password"
              />
            </label>
            <label for="new_password">
              <p className="font-medium text-slate-700 pb-2 pl-2">
                Retype New Password
              </p>
              <input
                id="old_password"
                name="password"
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full py-3 border border-slate-200 rounded-lg px-3 focus:outline-none focus:border-slate-500 hover:shadow"
                placeholder="Enter your current password"
              />
            </label>

            <button
              className="flex w-full justify-center rounded-md border border-transparent bg-yellow-600 py-2 px-4 text-md font-medium text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
              onClick={handleSubmit}
              onKeyDown={handleSubmit}
            >
              <span>Reset password</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
