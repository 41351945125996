import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./index.css";
import Checkout from "./pages/Checkout";
import Homepage from "./pages/Homepage";
import NotFound from "./pages/NotFound";
import ShoppingCart from "./pages/ShoppingCart";
import Gallery from "./pages/Gallery";
import { Package } from "./pages/Package";
import Contact from "./pages/Contact";
import Signin from "./pages/Signin";
import StartBooking from "./pages/StartBooking";
import Register from "./pages/Register";
import { Product } from "./pages/Product";
import { AnimatePresence } from "framer-motion";
import Account from "./pages/Account";
import { PackageInfoPage } from "./pages/PackageInfoPage";
import { BookingInfoPage } from "./pages/BookingInfo";
import { Fps } from "./pages/Fps";
import { PaymentSuccess } from "./pages/PaymentSuccess";
import { PaymentFailure } from "./pages/PaymentFailure";
import { Logout } from "./pages/Logout";
import { MyTransaction } from "./pages/MyTransaction";
import ConfirmPage from "./pages/ConfirmPage";
import { ForgetPassword } from "./pages/ForgetPassword";
import { EmailVerify } from "./pages/EmailVerify";
import { EmailConfirm } from "./pages/EmailConfirm";
import { Admin } from "./pages/Admin";
import { UpdateContent } from "./pages/UpdateContent";
import UserDetails from "./pages/UserDetails";
import { CreateBooking } from "./pages/CreateBooking";
import { Invoice } from "./pages/Invoice";
import ConfirmPaymentOnlyPage from "./pages/ConfirmPaymentOnlyPage";
import { PaymeBooking } from "./pages/PaymeBooking";
import { Payme } from "./pages/Payme";
import { FpsBooking } from "./pages/FpsBooking";

export default function AnimatedRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Homepage />} />
        <Route path="payme" element={<Payme />} />
        <Route path="paymeBooking" element={<PaymeBooking />} />
        <Route path="fps" element={<Fps />} />
        <Route path="fpsBooking" element={<FpsBooking />} />
        <Route path="invoice/:id" element={<Invoice />} />
        <Route path="admin" element={<Admin />} />
        <Route path="admin/:id" element={<Admin />} />
        <Route path="account" element={<Account />} />
        <Route path="logout" element={<Logout />} />
        <Route path="forget_pw" element={<ForgetPassword />} />
        <Route path="payment_success" element={<PaymentSuccess />} />
        <Route path="payment_failure" element={<PaymentFailure />} />
        <Route path="my_transaction" element={<MyTransaction />} />
        <Route path="checkout" element={<Checkout />} />
        <Route path="confirm_page" element={<ConfirmPage />} />
        <Route
          path="confirm_payment_only_page"
          element={<ConfirmPaymentOnlyPage />}
        />
        <Route path="email_verify" element={<EmailVerify />} />
        <Route path="email_confirm" element={<EmailConfirm />} />
        <Route path="packageInfo/:id" element={<PackageInfoPage />} />
        <Route path="bookingInfo/:id" element={<BookingInfoPage />} />
        <Route path="package" element={<Package />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="cart" element={<ShoppingCart />} />
        <Route path="contact" element={<Contact />} />
        <Route path="createBooking" element={<CreateBooking />} />
        <Route path="*" element={<NotFound />} />
        <Route path="register" element={<Register />} />
        <Route path="signin" element={<Signin />} />
        <Route path="booking" element={<Product />} />
        <Route path="start_booking" element={<StartBooking />} />
        <Route path="user_details/:id" element={<UserDetails />} />
        <Route path="update_content" element={<UpdateContent />} />
      </Routes>
    </AnimatePresence>
  );
}
