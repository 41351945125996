import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
export const UserContext = createContext(null);

function UserProvider({ children }) {
  const [userRole, setUserRole] = useState("guest");
  const navigate = useNavigate();
  useEffect(() => {
    // Get the JWT token from local storage or other source
    const token = localStorage.getItem("token");
    console.log("checking");
    if (token) {
      // Decode the JWT token and extract the user's role
      verifyToken(token);
    }
  }, []);

  const verifyToken = async (token) => {
    const response = await fetch(
      "https://studio-tableau-prd.onrender.com/api/auth/verifyJWT",
      {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const string = await response.text();
    const json = string === "" ? {} : JSON.parse(string);
    console.log(json);
    if (json.result) {
      // Set the user's role in state
      setUserRole(json.role);
    } else {
      navigate("/logout", { replace: true });
    }
  };

  return (
    <UserContext.Provider value={{ userRole, setUserRole }}>
      {children}
    </UserContext.Provider>
  );
}

export default UserProvider;
