import React, { useEffect } from "react";
import queryString from "query-string";
import useToken from "../components/useToken";

export const SuccessWidget = () => {
  const queryParams = queryString.parse(window.location.search);
  const { token } = useToken();

  useEffect(() => {
    confirmSuccess();
  }, []);

  const confirmSuccess = async () => {
    try {
      fetch("https://studio-tableau-prd.onrender.com/api/payment/success", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          iv: queryParams.iv,
          content: queryParams.content,
          session_id: queryParams.session_id ?? "",
        }),
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="body-font p-8 bg-gray-50 pt-10 text-gray-600">
      <div className="container mx-auto mt-10 flex max-w-5xl flex-wrap justify-center rounded-lg bg-white px-5 py-24">
        <div className="flex-wrap md:flex">
          <div className="bg-white p-6  md:mx-auto">
            <svg
              viewBox="0 0 24 24"
              className="text-green-600 w-16 h-16 mx-auto my-6"
            >
              <path
                fill="currentColor"
                d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
              ></path>
            </svg>
            <div className="text-center">
              <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
                {/* 付款成功 */}
                Payment Success
              </h3>
              <p className="text-gray-600 my-2">
                {/* 感謝您的購買，您的交易已被確認。 */}
                Thank you for your purchase, you payment is confirmed.
              </p>
              {/* <p> Have a great day! </p> */}
              <div className="py-10 text-center">
                <a
                  href="/account"
                  className="flex w-full justify-center rounded-md border border-transparent bg-yellow-600 p-4 text-md font-medium text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
                >
                  {/* 回到我的紀錄 */}
                  Back to My Transaction Record
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
