import React from "react";
import InfoPackage from "../components/InfoPackage";
import Layout from "./Layout";
import { motion } from "framer-motion";
export const PackageInfoPage = () => {
  return (
    <Layout>
      <motion.div exit={{ opacity: 0 }}>
        <InfoPackage />
      </motion.div>
    </Layout>
  );
};
