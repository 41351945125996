import React from "react";

const StatisticsCard = (props) => {
  return (
    <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <span className="text-2xl sm:text-3xl leading-none font-bold text-gray-900">
            {props.data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </span>
          <h3 className="text-base font-normal text-gray-500 text-sm sm:text-lg">
            {props.title}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default StatisticsCard;
