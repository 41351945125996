import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

export default function HomepageGalleryWidget() {
  const [photos, setPhotos] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setPhotos([]);
        const response = await fetch(
          "https://studio-tableau-prd.onrender.com/api/sliders/home",
          {
            method: "get",
            headers: {
              Accept: "application/json, text/plain, */*",
              "Content-Type": "application/json",
            },
          }
        );
        const string = await response.text();

        const json = string === "" ? {} : JSON.parse(string);
        console.log(json);
        setPhotos(json.image);
      } catch (error) {
        console.log(error);
        await fetchData();
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {photos && photos.length > 0 && (
        <Carousel
          animationHandler="fade"
          autoPlay={true}
          showThumbs={false}
          interval={3000}
          infiniteLoop={true}
          swipeable={false}
        >
          {photos.map((photo) => (
            <div key={photo.id}>
              <img
                // className="object-cover h-[calc(100vh-160px)]"
                className="object-scale-down"
                src={photo.url}
                alt=""
              />
            </div>
          ))}
        </Carousel>
      )}
      {photos.length == 0 && (
        <img
          // className="object-cover h-[calc(100vh-160px)] w-full"
          className="object-scale-down w-full"
          src="https://studiotableau.hk/images/home/9.webp"
        />
      )}
    </div>
  );
}
